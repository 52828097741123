import { UUIDv4 } from "uuid-v4-validator";
import { questionTypes } from "../../components/questionnaires/questionnaire-builder/QuestionnarieBuilderOptions";
export const topEligibilityQuestSetID = "6b122624-49cb-4b3d-a30d-585c20652764";

export const eligibilityQuestionIDs: any = {
  pregnancy_test: "a54fd99a-f912-48c8-8801-affb1947cc4e",
  last_menstrual_period: "2de95bed-18c5-4880-a820-32feb0563cc8",
  day_of_your_last_period: "0315e15d-ec61-489b-ace8-ca17b573185f",
  date_of_birth: "f09c0f7c-0c28-4af8-aeed-2e629def15dd",
  are_you_safe: "b74890e6-020a-4380-aac2-3e9b4b4df86a",
  more_about_your_situation: "6689c3dd-8afe-4f9b-a364-8f21d55cba0b",
  trust_know_about_this_pregnancy: "992be9ea-5231-42e5-ac4e-435b4225e6a0",
  have_any_support: "8c9e5886-5f64-4c5f-b643-c385bbffa5c4",
  registered_with_UK_GP: "6b300170-91cb-4fdd-93ce-7c8604351ced",
  GP_organisationCode: "dcb67033-557c-4ead-897b-ee94ff988e3d",
  practitioner_name: "1f1a9790-c0a2-4826-90fb-c6aac1c8d0ca",
  street_name: "7cd2e934-702c-4859-a28c-b627b0081b4c",
  Town_or_City: "58089dcb-261b-4725-86f2-620a1ae52fbc",
  postcode: "468f7097-cbb1-484d-8be1-ed7267280a30",
  contact_your_GP: "36b21a52-a245-4519-b908-46242e6c10f9",
  is_this_your_registered_UK_address: "c4f9ac09-2215-474b-856c-30eb19af33fb",
  have_UK_address: "d809269f-d34b-4060-8cb5-e03678541e4d",
  uk_address_house_or_flat_no: "4893d3f6-b992-4f87-8c0c-aaa4266bfe47",
  uk_address_street_name: "de21cd88-9d19-471c-8c82-6beacb3f2dc9",
  uk_address_town_or_city: "bf4e741f-bfae-4565-8732-d954f4402f9d",
  uk_address_postcode: "6a111e98-59c5-4d90-80fc-fd21aba407b1",
  phone_no_call_you_on: "d7c270d0-12d4-4f54-8dc6-b30820b003db",
  ubrn_number: "20eab0e9-bb38-4966-b026-161d94f1e71a",
  book_counselling: "1c8c31bf-6376-4cea-82bf-b23395fb151f",
  already_contacted_local_provider: "70b77c2b-7ca8-40e8-837d-a0d18bc9dec7",
  what_local_provider_said_to_you: "6681cf1e-238a-4f10-ad29-ef1ae6ecf223",
  private_options: "fc71975f-421d-433e-b99b-3810f429774b",
  new_phone_no: "3ac7a43a-3430-4949-962d-299cf519f717",
  why_not_contacted_them: "b7fe3ecf-b013-41ea-96d1-c1c70b583579",
  ubrnPostcode: "74a76807-7be0-413a-bee5-bbed65117baa",
  call_day: "61fc159c-3821-404c-b2c9-806d22191f91",
  call_prefer: "cfe4f34b-fbe0-4c4e-b8f7-4adcc99b0236",
  call_stage: "e0499ca4-8833-4c77-9050-d3198cb03d60",
  call_date: "a14710fe-3292-4d25-ba35-1a502f168b77",
  call_tz: "985eb3a1-ef0d-4054-ad63-2f5a4c1b11ba",
  appnt_call_day: "a4867409-de82-4951-a2e6-47719416d8d3",
  appnt_call_prefer: "52e40120-a8ab-473d-901f-486fa67ec941",
  appnt_call_stage: "cd040644-c5b2-45ce-87b7-664d61a2dd8e",
  appnt_call_date: "798d98aa-a553-4e83-a251-1f5c64fc60b4",
  appnt_call_tz: "08dadc09-47be-40fd-b4d5-4496ad214931",

  have_you_had_scan: "485e7c30-2a46-49f4-9ff4-59a27ebed803",
  which_date_did_your_scan: "be3b96d9-1482-4b1b-827f-eec2d2c75b10",
  number_of_weeks: "599735a9-2a62-4822-890b-f9954e6ddd7a",
  number_of_days: "fba4c268-f9d9-4a96-8d1a-2fa2e9d56127",
  first_day_last_menstrual_period: "09130b31-aebf-4259-a21d-ba4714cbc6a5",
  approximately_week_last_menstrual_period:
    "518576a7-0fbd-496e-b690-4829bc051eaa",
  earliest_possible_date_of_period_started:
    "1a9300c6-73e5-4026-910f-e49b4b145064",
  when_might_have_conceived: "3479326c-d231-4095-8dbe-aa821e68c2fd",
  date_you_think_you_conceived: "54ac6d1d-f3bd-408b-b50d-c63dc0e5cd40",
  risk_of_harm: "f6626f6d-dc89-4806-b1b3-7128a75ddb8f",
  harm_are_you_at_risk_of: "097e51ba-0934-4638-aa62-53d32c33fb8c",
  risk_of_other_option: "9176bdb4-b49f-41d9-aa5e-ebd192c851f3",
  explain_more_about_your_risk: "3c6f5981-02a6-4e7c-9cdc-b4a5973b3b50",
  immediate_risk_of_harm: "4b760cb7-5f46-45b1-bbe2-c3168507ac16",
  harm_are_you_at_immediate_risk_of: "70118260-3464-4389-ae6d-3459ada60b24",
  immediate_risk_of_other_option: "8bfb5a74-38d8-4fa8-b221-769dc4335f31",
  explain_more_about_your_immediate_risk:
    "6b154d6a-3cc3-4db2-9013-ba2ca4dff54c",
  contact_you_by_phone_to_ensure_your_wellbeing:
    "57974a00-9ecf-4783-a28f-f9afdcb26303",
  safest_contact_you_by_phone_to_ensure_your_wellbeing:
    "827c18f6-cc56-461c-8442-c079b9c593e6",
  bluebell_suit_told_contact_msi_UK: "b4297063-05c7-4783-81cd-e0be9c80bbec",
  GP_told_you_contact_MSI_UK: "b388e155-c092-4998-9ab9-d0503a8efdb1",
  do_have_code_from_bluebell_suite_FPC: "63d8888e-ee2e-4e32-ba9b-a57e11b0c143",
  bluebell_suite_FPC_code: "fc166c72-e1a9-4b58-a94f-d896a4b17038",
  hope_house_code: "8f25baef-607a-43d8-82f0-b4d1d8386312",
  need_to_contact_GP_happy_of_us_to_do: "ea429a3f-7e66-41d7-a22a-b1f61e7472f3",
  // like_to_book_counselling: "79b891c6-3a79-4a29-80aa-f30bd21a6838",
  appointment_question_setid: "ca5d9362-0a4f-4e76-bfaa-d930c60c3cff",
};

export const eligibilityOptionsIDs: any = {
  have_you_had_scan_yes: "3e60f560-1f19-495e-b33c-f87703505224",
  have_you_had_scan_no: "94618026-6019-4c76-8359-92a5fef4d237",
  first_day_last_menstrual_period_yes: "099f218b-5c0e-4a6c-93ee-d6f133b2cf67",
  first_day_last_menstrual_period_no: "9ccbb495-7de6-494e-ba8d-7bc874a50c6f",
  approximately_week_last_menstrual_period_yes:
    "4af8203e-9880-4026-bc41-212cc4cc75e1",
  approximately_week_last_menstrual_period_no:
    "017b71e8-a4bf-4ba7-a80d-486688913b69",
  when_might_have_conceived_yes: "88bab169-fcd7-4c31-8b56-675c570fba2a",
  when_might_have_conceived_no: "5f132316-9eab-40d9-b61b-5dd077a09886",
  risk_of_harm_yes: "164d7b8a-5eed-4278-97b4-cda6d31f21bc",
  risk_of_harm_no: "6a614cb7-eb60-4aa6-8a1d-bc5c3f0444d4",
  risk_of_harm_physical_harm: "a20c7d95-9a3e-478c-88f2-c0e3932678fc",
  risk_of_harm_emotional_abuse: "ea910387-d906-40a7-877b-36cac5a28606",
  risk_of_harm_financial_abuse: "db94be8c-6a03-4c4c-a8da-31bd5800231c",
  risk_of_harm_suicidal_thoughts: "5916e6fa-3df6-416d-afc3-1ae1b87c0e01",
  risk_of_harm_self_harm: "1361e9d9-0413-4aba-889c-f26b4caa62a3",
  risk_of_harm_homelessness: "409c4970-e48e-4fef-9a20-8863bdf7ff6c",
  risk_of_harm_neglect: "f160d463-3a7c-4389-bd15-3bf55a28f9d3",
  risk_of_harm_sexual_assault: "1c1eb585-f789-4807-9968-479db9cfcc70",
  risk_of_harm_social_services_involvement:
    "70849c22-9a49-4726-aa36-39d6ed038521",
  risk_of_harm_other: "fa096026-dafb-49c3-8153-5a08c900674d",
  immediate_risk_of_harm_yes: "bef2f390-b5a2-45f4-835e-4c93be9dca89",
  immediate_risk_of_harm_no: "4ca2e2e6-ec58-41a4-ae6e-b274ee8e6072",
  immediate_risk_of_harm_physical_harm: "1829585d-8e65-490b-ad9e-b82f8bde1bf9",
  immediate_risk_of_harm_emotional_abuse:
    "53546a4b-5658-48bd-845e-4e4d1d3a70dd",
  immediate_risk_of_harm_financial_abuse:
    "acadda37-cbee-4d34-8141-67afe558a42f",
  immediate_risk_of_harm_suicidal_thoughts:
    "fca95a4f-d2bd-42ba-8a86-72b3b67cbd5d",
  immediate_risk_of_harm_self_harm: "c583aa06-5053-4a97-a1ee-266a0aed8363",
  immediate_risk_of_harm_homelessness: "4e38faba-3620-454a-a36f-bec08dc6c9b4",
  immediate_risk_of_harm_neglect: "54847983-7ec8-48e5-9778-9c215681ed86",
  immediate_risk_of_harm_sexual_assault: "39398b33-38d5-4dd8-b594-0a39806d4706",
  immediate_risk_of_harm_social_services_involvement:
    "10a93228-dfb0-4bf5-8023-f6c3921a1ef4",
  immediate_risk_of_harm_other: "cdcae3d5-7529-4db7-8d48-2bd5bb48b2e3",
  contact_you_by_phone_to_ensure_your_wellbeing_yes:
    "33b1f10a-158f-466e-9e12-627fbd0d1c20",
  contact_you_by_phone_to_ensure_your_wellbeing_no:
    "3ef5d1d3-dab4-41bc-944f-0b031de5fb2f",

  bluebell_suit_told_contact_msi_UK_yes: "0f124e4d-c902-4762-824b-a7cda934bac3",
  bluebell_suit_told_contact_msi_UK_no: "23175368-54ec-4e3e-b1de-133491314b1e",
  GP_told_you_contact_MSI_UK_yes: "2a3bd4b9-9a9e-4af9-b01f-1cfd5e7b3cd2",
  GP_told_you_contact_MSI_UK_no: "b494474b-cfac-4342-9f0d-8bd3543aa5ec",
  do_have_code_from_bluebell_suite_FPC_yes:
    "239d33aa-f929-4bc6-ba7b-a3044c1882de",
  do_have_code_from_bluebell_suite_FPC_no:
    "bd904a6a-e9b9-4e70-8f52-4bb2aaf658fa",
  need_to_contact_GP_happy_of_us_to_do_yes:
    "cafb50f7-76e5-47a5-8c1c-5d7687b26c1b",
  need_to_contact_GP_happy_of_us_to_do_no:
    "dddd73c3-d81a-40cc-b13b-aa47a95bb992",
  // like_to_book_counselling_yes: "98f03afa-7003-41ec-8bf5-a6bd607a5ba0",
  // like_to_book_counselling_no: "8ae73741-ac06-43c4-bdda-30868ba82433",
  contact_you_by_phone_to_ensure_your_wellbeing_phone:
    "3e9d14de-9b31-4aae-a965-563cf60a63e7",
  contact_you_by_phone_to_ensure_your_wellbeing_text:
    "58077ef4-6643-49b2-bd9f-5daed7873e2a",
  contact_you_by_phone_to_ensure_your_wellbeing_email:
    "b41b7138-229c-44f0-ae0c-99da9f881ea6",
  contact_you_by_phone_to_ensure_your_wellbeing_not_safe:
    "1089081f-9990-489f-a48c-197dd8eae06e",
  ubrn_number: "328970ba-6ba0-4a39-bf77-e345cdb593b9",
  unrn_passcode: "fcb9957a-2b2f-4c14-b8ad-7a0b4740b1a0",
};

export const getQuestionById = (id: String, data: any) => {
  return data?.QuestionSetIds?.questions?.find((x: any) => x.id === id)
    ?.question;
};
export const getDescriptionById = (id: String, data: any) => {
  return data?.QuestionSetIds?.questions?.find((x: any) => x.id === id)
    ?.description;
};

export const getAnsByQid = (id: String, data: any) => {
  const questionIndex = data?.QuestionSetIds?.questions?.findIndex(
    (x: any) => x.id === id
  );
  let ans: any = "";
  if (questionIndex > -1) {
    const question = data?.QuestionSetIds?.questions[questionIndex];
    const new_data = JSON.parse(JSON.stringify(data));
    if (
      new_data.QuestionSetIds.questions[questionIndex].userAnswer &&
      new_data.QuestionSetIds.questions[questionIndex].userAnswer.length > 0
    ) {
      if (
        question.type === questionTypes.yesnoquestion ||
        question.type === questionTypes.dropdown
      ) {
        ans = data?.QuestionSetIds?.questions?.find((x: any) => x.id === id)
          ?.userAnswer?.[0]?.optionId;
      } else if (question.type === questionTypes.multiplechoice) {
        ans = data?.QuestionSetIds?.questions?.find(
          (x: any) => x.id === id
        )?.userAnswer;
      } else {
        ans = data?.QuestionSetIds?.questions?.find((x: any) => x.id === id)
          ?.userAnswer?.[0]?.answer;
      }
    }
  }
  return ans;
};

export const getPhoneCallAnsByQid = (id: String, questions: any) => {
  const questionIndex = questions?.findIndex((x: any) => x.id === id);
  let ans = "";
  if (questionIndex > -1) {
    ans = questions[questionIndex].userAnswer?.[0]?.answer;
  }
  return ans;
};

export const getPhoneCallOptionQuestionId = (
  question: String,
  phone_call_questions_data: any
) => {
  const questionIndex = phone_call_questions_data?.findIndex(
    (x: any) => x.question === question
  );
  let ans = "";
  if (questionIndex > -1) {
    ans = phone_call_questions_data[questionIndex].id;
  }
  return ans;
};

export const getAllPhoneCallQuestionsByQuestionID = (
  questionId: String,
  questions: any
) => {
  return questions.filter(function (el: any) {
    // return el.dependentQuestionId == questionId && (el.question == 'Do you have a preference for when we call you?' || el.question == 'Would you prefer us to call you in the:')
    return el.dependentQuestionId == questionId;
  });
};

export const checkClientAnswerForPhoneCallQuestion = (questions: any) => {
  let count = 0;
  const overall_questions = questions.length;
  questions.forEach(function (index: any) {
    if (
      index.userAnswer &&
      index.userAnswer.length > 0 &&
      index.userAnswer[0].answer != null &&
      index.userAnswer[0].answer != ""
    ) {
      count = count + 1;
    }
  });
  if (count == overall_questions) {
    return false;
  } else {
    return true;
  }
};

export const checkAnsExitByQid = (id: String, data: any, optionID: string) => {
  const questionIndex = data?.QuestionSetIds?.questions?.findIndex(
    (x: any) => x.id === id
  );
  let ans = [];
  if (questionIndex > -1) {
    const question = data?.QuestionSetIds?.questions[questionIndex];
    const new_data = JSON.parse(JSON.stringify(data));
    if (
      new_data.QuestionSetIds.questions[questionIndex].userAnswer &&
      new_data.QuestionSetIds.questions[questionIndex].userAnswer.length > 0
    ) {
      if (question.type === questionTypes.multiplechoice) {
        ans = data?.QuestionSetIds?.questions?.find(
          (x: any) => x.id === id
        )?.userAnswer;
      }
    }
  }
  if (ans && ans.length) {
    const response = ans.filter((ans: any) => ans.optionId === optionID);
    if (response && response.length) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

export const setAnswerByQid = (id: String, data: any, value: any) => {
  const questionIndex = data?.QuestionSetIds?.questions?.findIndex(
    (x: any) => x.id === id
  );
  if (questionIndex > -1) {
    const question = data?.QuestionSetIds?.questions[questionIndex];
    const new_data = JSON.parse(JSON.stringify(data));
    if (new_data.QuestionSetIds.questions[questionIndex].userAnswer) {
      if (
        new_data.QuestionSetIds.questions[questionIndex].userAnswer.length > 0
      ) {
        if (
          question.type === questionTypes.yesnoquestion ||
          question.type === questionTypes.dropdown
        ) {
          new_data.QuestionSetIds.questions[
            questionIndex
          ].userAnswer[0].optionId = UUIDv4.validate(value) ? value : null;
        } else if (question.type === questionTypes.multiplechoice) {
          new_data.QuestionSetIds.questions[questionIndex].userAnswer =
            value && value.length > 0 ? value : null;
        } else {
          new_data.QuestionSetIds.questions[
            questionIndex
          ].userAnswer[0].answer = value;
        }
      } else {
        if (
          question.type === questionTypes.yesnoquestion ||
          question.type === questionTypes.dropdown
        ) {
          new_data.QuestionSetIds.questions[questionIndex].userAnswer = [
            { optionId: UUIDv4.validate(value) ? value : null },
          ];
        } else if (question.type === questionTypes.multiplechoice) {
          new_data.QuestionSetIds.questions[questionIndex].userAnswer = [
            ...value,
          ];
        } else {
          new_data.QuestionSetIds.questions[questionIndex].userAnswer = [
            { answer: value },
          ];
        }
      }
    } else {
      if (
        question.type === questionTypes.yesnoquestion ||
        question.type === questionTypes.dropdown
      ) {
        new_data.QuestionSetIds.questions[questionIndex] = {
          userAnswer: [{ optionId: UUIDv4.validate(value) ? value : null }],
          ...new_data.QuestionSetIds.questions[questionIndex],
        };
      } else if (question.type === questionTypes.multiplechoice) {
        new_data.QuestionSetIds.questions[questionIndex].userAnswer =
          value && value.length > 0 ? value : null;
        // {
        //   userAnswer: [...value],
        //   ...new_data.QuestionSetIds.questions[questionIndex],
        // };
      } else {
        new_data.QuestionSetIds.questions[questionIndex] = {
          userAnswer: [{ answer: value }],
          ...new_data.QuestionSetIds.questions[questionIndex],
        };
      }
    }
    return new_data;
  }
};
function getStartOfDay(lmpDate: any) {
  const date = new Date(lmpDate);
  date.setHours(0, 0, 0, 0);
  return date;
}

export const getScanGestationCal = (
  scanReportDate: Date,
  scanGestationWeeks: number,
  scanGestationDays: number
) => {
  // Calculate gestation duration in milliseconds
  const gestationDuration =
    (scanGestationWeeks * 7 + scanGestationDays) * 24 * 60 * 60 * 1000;

  // Calculate gestation date timestamp
  const gestationDateTimestamp = scanReportDate.getTime() - gestationDuration;

  // Create a new Date object for the gestation date
  const gestationDate = new Date(gestationDateTimestamp);

  // Calculate the time difference between gestation date and today
  const currentDate = new Date();
  const timeDifference = currentDate.getTime() - gestationDate.getTime();

  // Calculate the number of weeks and days that have passed
  const elapsedWeeks = Math.floor(timeDifference / (7 * 24 * 60 * 60 * 1000));
  const elapsedDays = Math.floor(
    (timeDifference % (7 * 24 * 60 * 60 * 1000)) / (24 * 60 * 60 * 1000)
  );
  return {
    gestationDate: gestationDate,
    elapsedWeeks: elapsedWeeks,
    elapsedDays: elapsedDays,
  };
};

export const getGestationDate = async (userAnswer: any) => {
  let gestation = null;
  if (
    getAnsByQid(eligibilityQuestionIDs.have_you_had_scan, userAnswer) ===
      eligibilityOptionsIDs.have_you_had_scan_yes &&
    getAnsByQid(eligibilityQuestionIDs.which_date_did_your_scan, userAnswer) !==
      "" &&
    getAnsByQid(eligibilityQuestionIDs.number_of_weeks, userAnswer) !== "" &&
    getAnsByQid(eligibilityQuestionIDs.number_of_days, userAnswer) !== ""
  ) {
    const date = new Date(
      getAnsByQid(eligibilityQuestionIDs.which_date_did_your_scan, userAnswer)
    );
    const weeks = Number(
      getAnsByQid(eligibilityQuestionIDs.number_of_weeks, userAnswer)
    );
    const days = Number(
      getAnsByQid(eligibilityQuestionIDs.number_of_days, userAnswer)
    );
    if (date && weeks >= 0 && days >= 0) {
      const scanGestation = await getScanGestationCal(date, weeks, days);
      gestation = scanGestation.gestationDate;
    }
  } else if (
    getAnsByQid(eligibilityQuestionIDs.have_you_had_scan, userAnswer) ===
    eligibilityOptionsIDs.have_you_had_scan_no
  ) {
    if (
      getAnsByQid(
        eligibilityQuestionIDs.first_day_last_menstrual_period,
        userAnswer
      ) === eligibilityOptionsIDs.first_day_last_menstrual_period_yes
    ) {
      if (
        getAnsByQid(
          eligibilityQuestionIDs.day_of_your_last_period,
          userAnswer
        ) !== ""
      ) {
        gestation = getAnsByQid(
          eligibilityQuestionIDs.day_of_your_last_period,
          userAnswer
        );
      }
    } else if (
      getAnsByQid(
        eligibilityQuestionIDs.first_day_last_menstrual_period,
        userAnswer
      ) === eligibilityOptionsIDs.first_day_last_menstrual_period_no
    ) {
      if (
        getAnsByQid(
          eligibilityQuestionIDs.approximately_week_last_menstrual_period,
          userAnswer
        ) === eligibilityOptionsIDs.approximately_week_last_menstrual_period_yes
      ) {
        if (
          getAnsByQid(
            eligibilityQuestionIDs.earliest_possible_date_of_period_started,
            userAnswer
          ) !== ""
        ) {
          gestation = getAnsByQid(
            eligibilityQuestionIDs.earliest_possible_date_of_period_started,
            userAnswer
          );
        }
      } else if (
        getAnsByQid(
          eligibilityQuestionIDs.approximately_week_last_menstrual_period,
          userAnswer
        ) === eligibilityOptionsIDs.approximately_week_last_menstrual_period_no
      ) {
        if (
          getAnsByQid(
            eligibilityQuestionIDs.when_might_have_conceived,
            userAnswer
          ) === eligibilityOptionsIDs.when_might_have_conceived_yes
        ) {
          if (
            getAnsByQid(
              eligibilityQuestionIDs.date_you_think_you_conceived,
              userAnswer
            ) !== ""
          ) {
            // Assuming dateOfLMP.questionAns is a valid date string or a Date object
            const lmpDate: Date = new Date(
              getAnsByQid(
                eligibilityQuestionIDs.date_you_think_you_conceived,
                userAnswer
              )
            );

            // Add 14 days to the LMP date
            const adjustedDate: Date = new Date(lmpDate);
            adjustedDate.setDate(lmpDate.getDate() - 14);
            gestation = adjustedDate;
          }
        }
      }
    }
  }
  return gestation;
};

export const gestrationCal = (
  date: Date,
  approximate: boolean,
  noToAll: boolean
) => {
  let gestration = 0;
  const gdate = date ? getStartOfDay(date) : null;

  if (gdate) {
    const newDate: Date = new Date();
    gestration = Math.floor(
      (newDate.getTime() - new Date(gdate).getTime()) / (1000 * 60 * 60 * 24)
    );
    return {
      gestration,
      gestationWeek: Number(Math.floor(gestration / 7)),
      gestationday: Number(gestration % 7),
      approximate,
      noToAll,
      totalDays:
        Number(Math.floor(gestration / 7) * 7) + Number(gestration % 7),
    };
  }
};

export const calculateGestation = async (userAnswer: any) => {
  if (
    getAnsByQid(eligibilityQuestionIDs.have_you_had_scan, userAnswer) ===
      eligibilityOptionsIDs.have_you_had_scan_yes &&
    getAnsByQid(eligibilityQuestionIDs.which_date_did_your_scan, userAnswer) !==
      "" &&
    getAnsByQid(eligibilityQuestionIDs.number_of_weeks, userAnswer) !== "" &&
    getAnsByQid(eligibilityQuestionIDs.number_of_days, userAnswer) !== ""
  ) {
    let gestration = 0;
    const date = getAnsByQid(
      eligibilityQuestionIDs.which_date_did_your_scan,
      userAnswer
    );
    const gdate = date ? getStartOfDay(date) : null;
    if (gdate) {
      const newDate: Date = new Date();
      gestration =
        Math.floor(
          (newDate.getTime() - new Date(gdate).getTime()) /
            (1000 * 60 * 60 * 24)
        ) +
        Number(
          getAnsByQid(eligibilityQuestionIDs.number_of_weeks, userAnswer)
        ) *
          7 +
        Number(getAnsByQid(eligibilityQuestionIDs.number_of_days, userAnswer));

      const gestationWeek = Number(Math.floor(gestration / 7));
      const gestationday = Number(gestration % 7);
      return {
        gestationWeek,
        gestationday,
        approximate: false,
        noToAll: false,
        totalDays: Number(gestationWeek * 7) + Number(gestationday % 7),
      };
    }
  } else if (
    getAnsByQid(eligibilityQuestionIDs.have_you_had_scan, userAnswer) ===
    eligibilityOptionsIDs.have_you_had_scan_no
  ) {
    if (
      getAnsByQid(
        eligibilityQuestionIDs.first_day_last_menstrual_period,
        userAnswer
      ) === eligibilityOptionsIDs.first_day_last_menstrual_period_yes
    ) {
      if (
        getAnsByQid(
          eligibilityQuestionIDs.day_of_your_last_period,
          userAnswer
        ) !== ""
      ) {
        return gestrationCal(
          getAnsByQid(
            eligibilityQuestionIDs.day_of_your_last_period,
            userAnswer
          ),
          false,
          false
        );
      }
    } else if (
      getAnsByQid(
        eligibilityQuestionIDs.first_day_last_menstrual_period,
        userAnswer
      ) === eligibilityOptionsIDs.first_day_last_menstrual_period_no
    ) {
      if (
        getAnsByQid(
          eligibilityQuestionIDs.approximately_week_last_menstrual_period,
          userAnswer
        ) === eligibilityOptionsIDs.approximately_week_last_menstrual_period_yes
      ) {
        if (
          getAnsByQid(
            eligibilityQuestionIDs.earliest_possible_date_of_period_started,
            userAnswer
          ) !== ""
        ) {
          return gestrationCal(
            getAnsByQid(
              eligibilityQuestionIDs.earliest_possible_date_of_period_started,
              userAnswer
            ),
            false,
            false
          );
        }
      } else if (
        getAnsByQid(
          eligibilityQuestionIDs.approximately_week_last_menstrual_period,
          userAnswer
        ) === eligibilityOptionsIDs.approximately_week_last_menstrual_period_no
      ) {
        if (
          getAnsByQid(
            eligibilityQuestionIDs.when_might_have_conceived,
            userAnswer
          ) === eligibilityOptionsIDs.when_might_have_conceived_yes
        ) {
          if (
            getAnsByQid(
              eligibilityQuestionIDs.date_you_think_you_conceived,
              userAnswer
            ) !== ""
          ) {
            // Assuming dateOfLMP.questionAns is a valid date string or a Date object
            const lmpDate: Date = new Date(
              getAnsByQid(
                eligibilityQuestionIDs.date_you_think_you_conceived,
                userAnswer
              )
            );

            // Add 14 days to the LMP date
            const adjustedDate: Date = new Date(lmpDate);
            adjustedDate.setDate(lmpDate.getDate() - 14);
            return gestrationCal(adjustedDate, true, false);
          }
        } else if (
          getAnsByQid(
            eligibilityQuestionIDs.when_might_have_conceived,
            userAnswer
          ) === eligibilityOptionsIDs.when_might_have_conceived_no
        ) {
          return gestrationCal(new Date(), true, true);
        }
      }
    }
  }
};

export const setAnswerbyArray = (data: any, dataArray: any) => {
  if (!data || !dataArray) {
    return;
  }
  let new_data = data;
  dataArray.map((ans: any) => {
    const questionIndex = new_data?.QuestionSetIds?.questions?.findIndex(
      (x: any) => x.id === ans.id
    );
    if (questionIndex > -1) {
      const question = data?.QuestionSetIds?.questions[questionIndex];
      new_data = JSON.parse(JSON.stringify(new_data));
      if (new_data.QuestionSetIds.questions[questionIndex].userAnswer) {
        if (
          new_data.QuestionSetIds.questions[questionIndex].userAnswer.length > 0
        ) {
          if (
            question.type === questionTypes.yesnoquestion ||
            question.type === questionTypes.dropdown
          ) {
            new_data.QuestionSetIds.questions[
              questionIndex
            ].userAnswer[0].optionId = UUIDv4.validate(ans.value)
              ? ans.value
              : null;
          } else if (
            question.type === questionTypes.multiplechoice &&
            ans.value === ""
          ) {
            new_data.QuestionSetIds.questions[questionIndex].userAnswer = [
              { optionId: null },
            ];
          } else {
            new_data.QuestionSetIds.questions[
              questionIndex
            ].userAnswer[0].answer = ans.value;
          }
        } else {
          if (
            question.type === questionTypes.yesnoquestion ||
            question.type === questionTypes.dropdown
          ) {
            new_data.QuestionSetIds.questions[questionIndex].userAnswer = [
              { optionId: UUIDv4.validate(ans.value) ? ans.value : null },
            ];
          } else if (
            question.type === questionTypes.multiplechoice &&
            ans.value === ""
          ) {
            new_data.QuestionSetIds.questions[questionIndex].userAnswer = [
              { optionId: null },
            ];
          } else {
            new_data.QuestionSetIds.questions[questionIndex].userAnswer = [
              { answer: ans.value },
            ];
          }
        }
      } else {
        if (
          question.type === questionTypes.yesnoquestion ||
          question.type === questionTypes.dropdown
        ) {
          new_data.QuestionSetIds.questions[questionIndex] = {
            userAnswer: [
              { optionId: UUIDv4.validate(ans.value) ? ans.value : null },
            ],
            ...new_data.QuestionSetIds.questions[questionIndex],
          };
        } else if (
          question.type === questionTypes.multiplechoice &&
          ans.value === ""
        ) {
          new_data.QuestionSetIds.questions[questionIndex] = {
            userAnswer: [{ optionId: null }],
            ...new_data.QuestionSetIds.questions[questionIndex],
          };
        } else {
          new_data.QuestionSetIds.questions[questionIndex] = {
            userAnswer: [{ answer: ans.value }],
            ...new_data.QuestionSetIds.questions[questionIndex],
          };
        }
      }
    }
  });
  return new_data;
};

export const clear_list_of_name_in_support = [
  "registered_with_UK_GP",
  "GP_organisationCode",
  "practitioner_name",
  "street_name",
  "Town_or_City",
  "postcode",
  "contact_your_GP",
  "is_this_your_registered_UK_address",
  "have_UK_address",
  "uk_address_house_or_flat_no",
  "uk_address_street_name",
  "uk_address_town_or_city",
  "uk_address_postcode",
  "phone_no_call_you_on",
  "ubrn_number",
  "book_counselling",
  "already_contacted_local_provider",
  "what_local_provider_said_to_you",
  "private_options",
  "new_phone_no",
  "why_not_contacted_them",
  "ubrnPostcode",
  "call_day",
  "call_prefer",
  "call_stage",
  "call_date",
  "call_tz",
  "bluebell_suit_told_contact_msi_UK",
  "GP_told_you_contact_MSI_UK",
  "do_have_code_from_bluebell_suite_FPC",
  "bluebell_suite_FPC_code",
  "hope_house_code",
  "need_to_contact_GP_happy_of_us_to_do",
];

export const list_of_name_in_dob = [
  "date_of_birth",
  "risk_of_harm",
  "harm_are_you_at_risk_of",
  "risk_of_other_option",
  "explain_more_about_your_risk",
  "immediate_risk_of_harm",
  "harm_are_you_at_immediate_risk_of",
  "immediate_risk_of_other_option",
  "explain_more_about_your_immediate_risk",
  "safest_contact_you_by_phone_to_ensure_your_wellbeing",
];

export const clear_list_of_name_in_dob = [
  "are_you_safe",
  "more_about_your_situation",
  "trust_know_about_this_pregnancy",
  "have_any_support",
  ...clear_list_of_name_in_support,
];

export const list_of_name_in_pregnancy = [
  "pregnancy_test",
  "have_you_had_scan",
  "which_date_did_your_scan",
  "number_of_weeks",
  "number_of_days",
  "last_menstrual_period",
  "day_of_your_last_period",
  "first_day_last_menstrual_period",
  "approximately_week_last_menstrual_period",
  "earliest_possible_date_of_period_started",
  "when_might_have_conceived",
  "date_you_think_you_conceived",
];

export const clear_list_of_name_in_pregnancy = [
  ...list_of_name_in_dob,
  ...clear_list_of_name_in_dob,
];

// To get eligibility id
export const getId = (value: string) => {
  return eligibilityQuestionIDs[value];
};

// To get eligibility id
export const getOptionId = (value: string) => {
  return eligibilityOptionsIDs[value];
};

// Date of birth flow - risk and immediate risk
export const riskCheckBoxOptions = [
  {
    label: "Physical harm",
    optionId: "risk_of_harm_physical_harm",
  },
  {
    label: "Emotional abuse",
    optionId: "risk_of_harm_emotional_abuse",
  },
  {
    label: "Financial abuse",
    optionId: "risk_of_harm_financial_abuse",
  },
  {
    label: "Suicidal thoughts",
    optionId: "risk_of_harm_suicidal_thoughts",
  },
  {
    label: "Self-harm",
    optionId: "risk_of_harm_self_harm",
  },
  {
    label: "Homelessness",
    optionId: "risk_of_harm_homelessness",
  },
  {
    label: "Neglect (by self or carer)",
    optionId: "risk_of_harm_neglect",
  },
  {
    label: "Sexual assault",
    optionId: "risk_of_harm_sexual_assault",
  },
  {
    label: "Social services involvement",
    optionId: "risk_of_harm_social_services_involvement",
  },
  {
    label: "Other",
    optionId: "risk_of_harm_other",
  },
];
// Date of birth flow - risk and immediate risk
export const iriskCheckBoxOptions = [
  {
    label: "Physical harm",
    optionId: "immediate_risk_of_harm_physical_harm",
  },
  {
    label: "Emotional abuse",
    optionId: "immediate_risk_of_harm_emotional_abuse",
  },
  {
    label: "Financial abuse",
    optionId: "immediate_risk_of_harm_financial_abuse",
  },
  {
    label: "Suicidal thoughts",
    optionId: "immediate_risk_of_harm_suicidal_thoughts",
  },
  {
    label: "Self-harm",
    optionId: "immediate_risk_of_harm_self_harm",
  },
  {
    label: "Homelessness",
    optionId: "immediate_risk_of_harm_homelessness",
  },
  {
    label: "Neglect (by self or carer)",
    optionId: "immediate_risk_of_harm_neglect",
  },
  {
    label: "Sexual assault",
    optionId: "immediate_risk_of_harm_sexual_assault",
  },
  {
    label: "Social services involvement",
    optionId: "immediate_risk_of_harm_social_services_involvement",
  },
  {
    label: "Other",
    optionId: "immediate_risk_of_harm_other",
  },
];

export const contactMethods = [
  {
    label: "Phone",
    optionId: "contact_you_by_phone_to_ensure_your_wellbeing_phone",
  },
  {
    label: "Text",
    optionId: "contact_you_by_phone_to_ensure_your_wellbeing_text",
  },
  {
    label: "Email",
    optionId: "contact_you_by_phone_to_ensure_your_wellbeing_email",
  },
  {
    label: "It isn’t safe to contact me",
    optionId: "contact_you_by_phone_to_ensure_your_wellbeing_not_safe",
  },
];

export const topIds = {
  explain_i_risk: "explain_more_about_your_immediate_risk",
  wellbeing: "safest_contact_you_by_phone_to_ensure_your_wellbeing",
};
