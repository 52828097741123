import { AxiosResponse } from "axios";
import jwtDecode from "jwt-decode";
import axiosAPI from "../middlewares/axios-interceptor";
// import { TOKEN_KEY, getCookie } from "../utils/useCookie";
// import { Options } from "react-sortablejs";

// import { history } from '../utils/history';
// const axios = Axios.create({
//   baseURL: `${process.env.REACT_APP_BASE_URL}/${process.env.REACT_APP_API_VERSION}`,
// });

// axios.interceptors.response.use(
//   (response) =>
//     new Promise((resolve) => {
//       resolve(response.data);
//     }),
//   (error) => {
//     if (!error.response) {
//       return new Promise((reject) => {
//         reject(error);
//       });
//     }
//     if (error.response.status === 401 || error.response.status === 403) {
//       localStorage.clear();
//       window.location.href = appUrls.loginform;
//     } else {
//       return new Promise((resolve, reject) => {
//         reject(error);
//       });
//     }
//   }
// );

// export function setToken(token: string | null, refreshToken: string | null) {
//   if (token && refreshToken) {
//     localStorage.setItem("access-token", token);
//     localStorage.setItem("refresh-token", refreshToken);
//     axios.defaults.headers.common.Authorization = `Bearer ${token}`;
//   } else {
//     delete axios.defaults.headers.common.Authorization;
//   }
// }

// function getHeaders() {
//   const accessToken = getCookie(TOKEN_KEY);
//   if (accessToken) {
//     return {
//       headers: {
//         Authorization: `Bearer ${accessToken}`,
//         // "x-refresh": refreshToken,
//       },
//     };
//   }
//   return undefined;
// }

// function getAdminHeaders() {
//   const sessionAccessToken = sessionStorage.getItem("access-token");
//   if (sessionAccessToken) {
//     return {
//       headers: {
//         Authorization: `Bearer ${sessionAccessToken}`,
//       },
//     };
//   }
//   return undefined;
// }

const apiFunctions = {
  get: async (url: string): Promise<AxiosResponse["data"]> => {
    return axiosAPI.get(url);
  },
  post: async (url: string, data: object): Promise<AxiosResponse["data"]> => {
    return axiosAPI.post(url, data);
  },
  put: async (url: string, data: object): Promise<AxiosResponse["data"]> => {
    return axiosAPI.put(url, data);
  },
  patch: async (url: string, data: object): Promise<AxiosResponse["data"]> => {
    return axiosAPI.patch(url, data);
  },
  delete: async (url: string) => {
    return axiosAPI.delete(url);
  },
  // postpicture: async (
  //   url: string,
  //   data: object
  // ): Promise<AxiosResponse["data"]> => {
  //   return axios.post(url, data, getHeaders());
  // },
  // getAdmin: async (url: string): Promise<AxiosResponse["data"]> => {
  //   return axios.get(url, getAdminHeaders());
  // },
  // postAdmin: async (
  //   url: string,
  //   data: object
  // ): Promise<AxiosResponse["data"]> => {
  //   return axios.post(url, data, getAdminHeaders());
  // },
  // putAdmin: async (
  //   url: string,
  //   data: object
  // ): Promise<AxiosResponse["data"]> => {
  //   return axios.put(url, data, getAdminHeaders());
  // },
  // patchAdmin: async (
  //   url: string,
  //   data: object
  // ): Promise<AxiosResponse["data"]> => {
  //   return axios.patch(url, data, getAdminHeaders());
  // },
  // deleteAdmin: async (url: string) => {
  //   return axios.delete(url, getAdminHeaders());
  // },
};

export function isTokenValid(token: string) {
  try {
    const decoded_jwt: any = jwtDecode(token);
    // const current_time = Date.now().valueOf() / 1000;
    // return decoded_jwt.exp > current_time;
    return decoded_jwt || false; // unlimited expiry
  } catch (error) {
    return false;
  }
}

// export default axios;
export default apiFunctions;