// initiate deployment process
import React, { Fragment } from "react";
//import { useNavigate } from 'react-router-dom';
import { Header } from "../components/Header";
import "../style/pages/auth.css";
import { logoutuser } from "../services/auth";
interface MainLayoutProps {
  children: any;
  headertext?: string;
}
import Loading from "../components/questionnaires/loading/Loading";
import { useDispatch } from "react-redux";
import { resetAuthState } from "../redux/authSlice";
import { TOKEN_KEY, removeCookie } from "../utils/useCookie";

export const MainLayout: React.FC<MainLayoutProps> = ({ children }) => {
  const [loader, setloader] = React.useState(false);
  const dispatch = useDispatch();
  
  async function logout() {
    setloader(true);
    const data = await logoutuser();
    if (data) {
      localStorage.clear();
      localStorage.removeItem("userDetails");
      removeCookie(TOKEN_KEY, { path: "/" })
      dispatch(resetAuthState());
      window.location.replace("/");
    }
  }
  return (
    <Fragment>
      <section>
        <Header probileshow />
        <div className="logout-text">
          <button onClick={logout}>
            <a>Logout</a>
          </button>
          {/* <a onClick={() => navigate("/profile")}>Profile</a>
          <a onClick={() => navigate("/")}>Home</a> */}
        </div>
        {children}
        {loader && (
          <div>
            <div className="overlay-logout"></div>
            <div className={`popup ${loader ? "loader" : ""}`}>
              <Loading status={loader} />
            </div>
          </div>
        )}
      </section>
    </Fragment>
  );
};
