import { lazy } from 'react';

export default [
    {
        title: 'Logout Page',
        component: lazy(() => import('./Logout')),
        url: '/logout',
        requirePermission: false,
        roles: ['Super_Admin', 'Admin', 'User'],
    },
    {
        title: 'Page Not Found Page',
        component: lazy(() => import('./PageNotFound')),
        url: '/pagenotfound',
        requirePermission: false,
        roles: ['Super_Admin', 'Admin', 'User'],
    },
    {
        title: 'T&C Page',
        component: lazy(() => import('./T&C')),
        url: '/T&C',
        requirePermission: false,
        roles: ['Super_Admin', 'Admin', 'User'],
    },
    {
        title: 'Declain T&C Page',
        component: lazy(() => import('./DeclainT&C')),
        url: '/declainT&C',
        requirePermission: false,
        roles: ['Super_Admin', 'Admin', 'User'],
    },
    // {
    //     title: 'Component Page',
    //     component: lazy(() => import('./StyleComponents')),
    //     url: '/stylecomponent',
    //     requirePermission: false,
    //     roles: ['Super_Admin', 'Admin', 'User'],
    // }
];
