import React from "react";

import { Link, useLocation } from "react-router-dom";

import "../../../style/questionnaire/Nav.css";
import useAuthContext from "../../../context/provider/AuthContext";
import { UserTypes } from "../../../models/UserTypes";
import { useSelector } from "react-redux";

interface PrimaryNavProps { }

const superAdminMenu = [
  {
    title: "Dashboard",
    link: "/admin/dashboard",
  },
  {
    title: "Appointments",
    link: "/admin/appointment/waitlist",
  },
  {
    title: "Forms & Questionnaires",
    link: "/questionnaires/form/all",
  },
  {
    title: "Admin functions",
    link: "/admin/upload-csv",
  },
];

const AdminMenu = [
  {
    title: "Dashboard",
    link: "/admin/dashboard",
  },
  {
    title: "Appointments",
    link: "/admin/appointment/waitlist",
  },
  {
    title: "Forms & Questionnaires",
    link: "/questionnaires/form/all",
  },
];

export const PrimaryNav: React.FC<PrimaryNavProps> = () => {
  const tabIndex = 0;
  const location = useLocation();
  const authSelector = useSelector((state: any) => state.userAuthReducer);
  const {
    userInfo: { usertype },
  } = authSelector;

  const navMenu =
    usertype === UserTypes.admin
      ? AdminMenu
      : usertype === UserTypes.superAdmin
        ? superAdminMenu
        : [];

  return (
    <>
      <nav className='primary-nav'>
        <ul className='nav-list'>
          {navMenu.map((item: any) => (
            <React.Fragment key={item.title}>
              <li className={`nav-item `}>
                <Link
                  tabIndex={tabIndex}
                  className={`no-underline ${location.pathname.includes(item.link) ? "active" : ""
                    }`}
                  to={item.link}
                >
                  {item.title}
                </Link>
              </li>
            </React.Fragment>
          ))}
        </ul>
      </nav>
    </>
  );
};
