import React, { ChangeEvent } from 'react';
import '../../../style/questionnaire/Checkbox.css';

import { getRandomText } from '../../../utils/HelperFunctions';

interface ICheckInputOptions {
  disabled?: boolean;
  label?: string;
  name?: string;
  type: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  errors?: string | null;
  addClass?: string;
  onlycheckbox?: boolean;
  selectedValue?: boolean;
  children?: any;
  id?: string;
}

// interface CheckBoxProps {}

export const CheckBox = function radio({
  label,
  onChange,
  name,
  type,
  errors,
  onBlur,
  addClass,
  onlycheckbox,
  selectedValue,
  children,
  disabled,
  id = getRandomText(10),
}: ICheckInputOptions) {
  // const tabIndex = 0;
  return (
    <div>
      <label
        htmlFor={id}
        className={`checkbox-group text-left ${addClass || ''} ${
          selectedValue === true ? 'active ' : ''
        }${onlycheckbox && 'onlycheckbox'}`}
      >
        {label}
        <input
          id={id}
          type={type}
          name={name}
          onChange={onChange}
          onBlur={onBlur}
          checked={selectedValue}
          disabled={disabled}
        />
        <span className='checkmark' />
        {children}
      </label>
      {errors && <div className='form-errors-msg'>{errors}</div>}
    </div>
  );
};

export default CheckBox;
