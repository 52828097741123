import { lazy } from "react";

export default [
  {
    title: "Questionnaires",
    component: lazy(() =>
      import("./FormApproval").then(({ AdminFormApproval }) => ({
        default: AdminFormApproval,
      }))
    ),
    url: "/questionnaires/form/approval",
    heading: "AdminFormApproval",
    requirePermission: true,
    roles: ["Super_Admin", "Admin"],
  },
  {
    title: "Create Questionnaires",
    component: lazy(() =>
      import("./Responses").then(({ AdminResponses }) => ({
        default: AdminResponses,
      }))
    ),
    url: "/questionnaires/form/responses",
    heading: "AdminResponses",
    requirePermission: true,
    roles: ["Super_Admin", "Admin"],
  },
  {
    title: "Client Record",
    component: lazy(() =>
      import("./ClientRecord").then(({ ClientRecord }) => ({
        default: ClientRecord,
      }))
    ),
    url: "/admin/dashboard/all-clients",
    requirePermission: true,
    roles: ["Super_Admin", "Admin"],
  },
  {
    title: "Trigger word",
    component: lazy(() =>
      import("./Triggerword").then(({ Triggerword }) => ({
        default: Triggerword,
      }))
    ),
    url: "/admin/dashboard/Triggerword",
    requirePermission: true,
    roles: ["Super_Admin", "Admin"],
  },
  {
    title: "Callback Old",
    component: lazy(() =>
      import("./CallBack").then(({ Callback }) => ({
        default: Callback,
      }))
    ),
    url: "/admin/dashboard/callbacks-old",
    requirePermission: true,
    roles: ["Super_Admin", "Admin"],
  },
  {
    title: "Callback",
    component: lazy(() =>
      import("./callback/Callback").then(({ CallbackNew }) => ({
        default: CallbackNew,
      }))
    ),
    url: "/admin/dashboard/callbacks/:tabName?",
    requirePermission: true,
    roles: ["Super_Admin", "Admin"],
  },
];
