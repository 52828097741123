import React from "react";
import Logo from "../../../assets/images/logo.svg";
import "../../../style/components/Header.css";
import "../../../style/pages/auth.css";
import { logoutuser } from "../../../services/auth";
import { useMsal } from "@azure/msal-react";
import {
  TOKEN_KEY,
  decodeUserInfo,
  getCookie,
  removeCookie,
} from "../../../utils/useCookie";
import { useDispatch } from "react-redux";
import { resetAuthState } from "../../../redux/authSlice";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface LogoHeaderProps { }

async function logout() {
  const data = await logoutuser();
  if (data) {
    localStorage.clear();
    localStorage.removeItem("userDetails");
    removeCookie(TOKEN_KEY, { path: "/" });
    // window.location.reload();
  }
}

export const LogoHeader: React.FC<LogoHeaderProps> = () => {
  const { instance } = useMsal();
  const dispatch = useDispatch();

  const handleLogout = (logoutType: string) => {
    const userToken: any = getCookie(TOKEN_KEY);
    if (userToken && logoutType === "popup") {
      const userInfo = decodeUserInfo(userToken);
      if (
        userInfo?.usertype == "Admin" ||
        userInfo?.usertype == "Super_Admin"
      ) {
        dispatch(resetAuthState());
        instance.logoutPopup({
          postLogoutRedirectUri: "/external",
          mainWindowRedirectUri: "/external",
        });
        logout();
      }
    } else {
      dispatch(resetAuthState());
      instance.logoutPopup({
        postLogoutRedirectUri: "/auth/login",
        mainWindowRedirectUri: "/auth/login", // redirects the top level app after logout
      });
      logout();
    }
  };

  return (
    <>
      <div className='secondary-header'>
        <img tabIndex={0} src={Logo} alt='msi logo' aria-label='MSI logo' />
        <button type='button' onClick={() => handleLogout("popup")}>
        <a>Logout</a>
        </button>
        <h1 className='sr-only'>Logo</h1>
      </div>
    </>
  );
};
