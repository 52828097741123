import React, { useEffect, useRef, useState } from 'react';
import '../style/components/Header.css';
import Exit from '../assets/images/exiticon.svg';
// import Logo from "../assets/images/logo.svg";
import { Button } from '../components/Button';
import { useLocation, useNavigate } from 'react-router-dom';
// import HamburgerMenu from "../components/HamburgerMenu";
// import { ReactComponent as Menuicon } from "../assets/images/Menu.svg";
// import { ReactComponent as Searchicon } from "../assets/images/Search.svg";
import Dashboardicon from '../assets/images/DashboardIcon.svg';
import Profileicon from '../assets/images/ProfileIcon.svg';
import useAuthContext from '../context/provider/AuthContext';
import { useDispatch, useSelector } from 'react-redux';
import { UserTypes } from '../models/UserTypes';
import { resetAuthState } from '../redux/authSlice';
import { TOKEN_KEY, removeCookie } from '../utils/useCookie';
interface HeaderProps {
  text?: string;
  probileshow?: boolean;
}

export const Header: React.FC<HeaderProps> = () => {
  const { authDispatch } = useAuthContext();

  const authSelector = useSelector((state: any) => state.userAuthReducer);

  const dispatch = useDispatch();

  const {
    userInfo: { usertype },
  } = authSelector;

  const exitSite = () => {
    window.open('http://beta-host.co.uk/', '_blank', 'noopener,noreferrer');
    localStorage.clear();
    localStorage.removeItem('userDetails');
    removeCookie(TOKEN_KEY, { path: '/' });
    dispatch(resetAuthState());
    window.open('https://www.bbc.com', '_blank', 'noopener,noreferrer');
    window.open('https://www.google.com', '_blank', 'noopener,noreferrer');
    window.open('https://www.bing.com', '_blank', 'noopener,noreferrer');
    history.replaceState({}, '', '/auth/login');
    window.open('about:blank', '_self');
    window.close();
  };


  const navigate = useNavigate();
  const headerRef = useRef<any>(null);
  const [headerHeight, setHeaderHeight] = useState('');
  const location = useLocation();
  const handleResize = () => {
    if (headerRef?.current?.clientHeight) {
      authDispatch({
        type: 'SET_HEADER_HEIGHT',
        headerHeight: JSON.stringify(headerRef?.current?.clientHeight),
      });
      setHeaderHeight(headerRef?.current?.clientHeight);
    }
  };

  // Get dynamic height
  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
  }, [headerHeight]);

  return (
    <>
      <div className='primary-header' ref={headerRef}>
        <Button
          type='submit'
          text='EXIT SITE'
          addClass='exit-btn'
          onClick={exitSite}
        >
          <img src={Exit} alt='exiticon' />
        </Button>

        <div className='menuside'>
          {location?.pathname.indexOf('signup/completeverification') > -1 ||
          location?.pathname.indexOf('signup/verificationcode') >
            -1 ? null : usertype === UserTypes.Client ? (
            <>
              <div>
                <button onClick={() => navigate('/')}>
                  <img src={Dashboardicon} alt='Dashboardicon' />
                </button>
              </div>
              <div>
                <button onClick={() => navigate('/client/account-settings')}>
                  <img src={Profileicon} alt='Profileicon' />
                </button>
              </div>
            </>
          ) : null}
        </div>
      </div>
    </>
  );
};
