import { AxiosError } from "axios";
import toast from "react-hot-toast";

import { IQuestionAnswerAPI } from "../../components/questionnaires/questionnaire-builder/QuestionnarieBuilderOptions";
import { IPatientQueryForApi } from "../../models/PatientModel";
import {
  IActionBtnInterface,
  IApprovalRequestQuestionnarie,
  IFavourite,
  IQuestionnaireQueryForAPI,
  IShareQuestionnarie,
  IStatusAprrovalQuestionnarie,
  saveDuplicateQuestionnaireInterface,
} from "../../models/QuestionnarieModel";
import apiFunctions from "../ApiService";
import { siteUrls } from "../SiteURLs";
import {
  errorHandler,
  objQueryConversion,
  objQueryConversionWithSearch,
} from "../../utils/HelperFunctions";

import { IPagination } from "../../models/QuestionnarieModel";
import { shareUser } from "../dashboard_data";

export function getQuestionnarieOptions() {
  return apiFunctions
    .get(`${siteUrls.questionnarie.getQuestionnarieOptions}`)
    .then((circle) => {
      return circle;
    })
    .catch((err: AxiosError) => {
      errorHandler(err);
    });
}

export function getAllQuestionnarieDetails() {
  return apiFunctions
    .get(`${siteUrls.questionnarie.getQuestionnarie}`)
    .then((circle) => {
      return circle;
    })
    .catch((err: AxiosError) => {
      errorHandler(err);
    });
}

// export function getRequestApprovalsCount() {
//   return apiFunctions
//     .get(`${siteUrls.questionnarie.requestApprovalsCount}`)
//     .then((result) => {
//       return result;
//     })
//     .catch((err: AxiosError) => {
//       errorHandler(err);
//     });
// }

export function createQuestionnarie(payload: any) {
  return apiFunctions
    .post(`${siteUrls.questionnarie.createQuestionnarie}`, payload)
    .then((circle) => {
      return circle;
    })
    .catch((err: AxiosError) => {
      errorHandler(err);
    });
}

export function createQBTriggerCategoryName(payload: any) {
  return apiFunctions
    .post(`${siteUrls.questionnarie.postQBTriggerCategoryName}`, payload)
    .then((circle) => {
      return circle;
    })
    .catch((err: AxiosError) => {
      errorHandler(err);
    });
}

export function updateQBTriggerWords(payload: any, id: string) {
  return apiFunctions
    .put(`${siteUrls.questionnarie.putQBTriggerWords}/${id}`, payload)
    .then((circle) => {
      return circle;
    })
    .catch((err: AxiosError) => {
      errorHandler(err);
    });
}

export function deleteQBTriggerCategory(id: string) {
  return apiFunctions
    .delete(`${siteUrls.questionnarie.deleteQBTriggerCategory}/${id}`)
    .then((result) => {
      return result;
    })
    .catch((err: AxiosError) => {
      errorHandler(err);
    });
}
export function checkQBTriggerCategory(id: string, word?: string) {
  return apiFunctions
    .get(
      word
        ? `${siteUrls.questionnarie.checkQBTriggerCategory}/${id}/${word}`
        : `${siteUrls.questionnarie.checkQBTriggerCategory}/${id}/null`
    )
    .then((result) => {
      return result;
    })
    .catch((err: AxiosError) => {
      errorHandler(err);
    });
}

export function getQBTriggerWords() {
  return apiFunctions
    .get(`${siteUrls.questionnarie.getQBTriggerCategoryName}`)
    .then((circle) => {
      return circle;
    })
    .catch((err: AxiosError) => {
      errorHandler(err);
    });
}

export function getAllStaffUsers(payload?: { searchText?: string }) {
  const queryString = objQueryConversionWithSearch(payload);
  let url: string;
  if (payload?.searchText && payload?.searchText.trim() && queryString) {
    url = `${siteUrls.user.getAllStaffUsers}?${queryString}`;
  } else {
    url = `${siteUrls.user.getAllStaffUsers}`;
  }
  return apiFunctions
    .get(url)
    .then((circle) => {
      return circle;
    })
    .catch((err: AxiosError) => {
      errorHandler(err);
    });
}

export function scheduleShareQuestionnarie(payload: any) {
  return apiFunctions
    .post(`${siteUrls.questionnarie.scheduleShareQuestionnarie}`, payload)
    .then((data) => {
      return data;
    })
    .catch((err: AxiosError) => {
      errorHandler(err);
    });
}

export function updateScheduleShareQuestionnarie(payload: any, id: string) {
  return apiFunctions
    .put(`${siteUrls.questionnarie.scheduleShareQuestionnarie}/${id}`, payload)
    .then((circle) => {
      return circle;
    })
    .catch((err: AxiosError) => {
      errorHandler(err);
    });
}

export function updateStaffUserStatus(payload: any, id: string) {
  return apiFunctions
    .put(`${siteUrls.user.updateStaffUserByID}/${id}`, payload)
    .then((circle) => {
      return circle;
    })
    .catch((err: AxiosError) => {
      errorHandler(err);
    });
}

export function updateQuestionnarie(payload: any) {
  return apiFunctions
    .post(`${siteUrls.questionnarie.updateQuestionnarie}`, payload)
    .then((circle) => {
      return circle;
    })
    .catch((err: AxiosError) => {
      errorHandler(err);
    });
}

export function updateQuestionnariename(payload: any) {
  return apiFunctions
    .post(`${siteUrls.questionnarie.updateQuestionnariename}`, payload)
    .then((circle) => {
      return circle;
    })
    .catch((err: AxiosError) => {
      errorHandler(err);
    });
}

export function commentQuestionnarie(payload: any) {
  return apiFunctions
    .post(`${siteUrls.questionnarie.commentQuestionnarie}`, payload)
    .then((circle) => {
      return circle;
    })
    .catch((err: AxiosError) => {
      errorHandler(err);
    });
}

export function getQuestionnarieComments(id: string) {
  return apiFunctions
    .get(`${siteUrls.questionnarie.commentQuestionnarie}/${id}`)
    .then((circle) => {
      return circle;
    })
    .catch((err: AxiosError) => {
      errorHandler(err);
    });
}

// export function ExportQuestionnaireCSV(payload: any) {
//   return apiFunctions
//     .post(`${siteUrls.questionnarie.exportQuestionnaireCsv}`, payload)
//     .then((data) => {
//       return data;
//     })
//     .catch((err: AxiosError) => {
//       errorHandler(err);
//     });
// }

// export function ResposeExportCSV(payload: any) {
//   const queryString = Object.keys(payload)
//     .map((key) => `${key}=${payload[key]}`)
//     .join("&");
//   let url: string;
//   if (queryString) {
//     url = `${siteUrls.questionnarie.responseExportCsv}?${queryString}`;
//   } else {
//     url = `${siteUrls.questionnarie.responseExportCsv}`;
//   }
//   return apiFunctions
//     .get(`${url}`)
//     .then((data) => {
//       return data;
//     })
//     .catch((err) => {
//       if (err?.response?.data.msg) {
//         toast.error(err?.response?.data.msg);
//       } else {
//         errorHandler(err);
//       }
//     });
// }

// export function ResponseDownloadPDF(payload: any) {
//   const queryString = objQueryConversion(payload);
//   let url: string;
//   if (queryString) {
//     url = `${siteUrls.questionnarie.resposeDownloadPdf}?${queryString}`;
//   } else {
//     url = `${siteUrls.questionnarie.resposeDownloadPdf}`;
//   }
//   return apiFunctions
//     .get(`${url}`)
//     .then((data) => {
//       return data;
//     })
//     .catch((err: AxiosError) => {
//       errorHandler(err);
//     });
// }

export function getAllTriggerCategory() {
  return apiFunctions
    .get(`${siteUrls.questionnarie.getAllTriggerCategory}`)
    .then((data) => {
      return data;
    })
    .catch((err: AxiosError) => {
      console.error(err);
      errorHandler(err);
    });
}

export function getQuestionnarieByID(id: string) {
  return apiFunctions
    .get(`${siteUrls.questionnarie.getQuestionnarieById}/${id}`)
    .then((circle) => {
      return circle;
    })
    .catch((err: AxiosError) => {
      errorHandler(err);
    });
}

export function approvalRequestQuestionnarie(
  payload: IApprovalRequestQuestionnarie
) {
  return apiFunctions
    .post(`${siteUrls.questionnarie.approvalRequest}`, payload)
    .then((questionnarie) => {
      return questionnarie;
    })
    .catch((err: AxiosError) => {
      errorHandler(err);
    });
}

export function receivedFormsApprovalService() {
  return apiFunctions
    .get(siteUrls.questionnarie.receivedApprovalRequest)
    .then((questionnarie) => {
      return questionnarie;
    })
    .catch((err: AxiosError) => {
      errorHandler(err);
    });
}

export function submittedFormsApprovalService(payload: IPagination) {
  const queryString = objQueryConversion(payload);
  let url: string;
  if (queryString) {
    url = `${siteUrls.questionnarie.submittedApprovalFormsList}?${queryString}`;
  } else {
    url = `${siteUrls.questionnarie.submittedApprovalFormsList}`;
  }
  return apiFunctions
    .get(url)
    .then((questionnarie) => {
      return questionnarie;
    })
    .catch((err: AxiosError) => {
      errorHandler(err);
    });
}

export function getAllQuestionnaireCompletedByUserID(id: string, payload: {}) {
  const queryString = objQueryConversion(payload);
  let url: string;
  if (queryString) {
    url = `${siteUrls.questionnarie.getFormsByUserID}/${id}?${queryString}`;
  } else {
    url = `${siteUrls.questionnarie.getFormsByUserID}/${id}`;
  }
  return apiFunctions
    .get(url)
    .then((questionnarie) => {
      return questionnarie;
    })
    .catch((err: AxiosError) => {
      errorHandler(err);
    });
}

// export function questionnarieApprovalService() {
//   return apiFunctions
//     .get(siteUrls.questionnarie.submittedApprovalFormsList)
//     .then((questionnarie) => {
//       return questionnarie;
//     })
//     .catch((err: AxiosError) => {
//       errorHandler(err);
//     });
// }
export function _getDashboardService() {
  return import("../dashboard_data");
}
export function getDashboardService(payload: any) {
  const queryString = objQueryConversion(payload);
  let url: string;
  if (queryString) {
    url = `${siteUrls.questionnarie.getDashboard}?${queryString}`;
  } else {
    url = `${siteUrls.questionnarie.getDashboard}`;
  }

  return apiFunctions
    .get(url)
    .then((questionnarie) => {
      return questionnarie;
    })
    .catch((err: AxiosError) => {
      errorHandler(err);
    });
}

// export function getAllQuestionnarie() {
//   return apiFunctions
//     .get(`${siteUrls.questionnarie.getAllQuestionnaire}`)
//     .then((circle) => {
//       return circle;
//     })
//     .catch((err: AxiosError) => {
//       errorHandler(err);
//     });
// }

// export function getActivityLogsQuestionnarie(payload: any) {
//   const queryString = objQueryConversion(payload);
//   let url: string;
//   if (queryString) {
//     url = `${siteUrls.questionnarie.getActivityLogs}?${queryString}`;
//   } else {
//     url = `${siteUrls.questionnarie.getActivityLogs}`;
//   }
//   return apiFunctions
//     .get(url)
//     .then((circle) => {
//       return circle;
//     })
//     .catch((err: AxiosError) => {
//       errorHandler(err);
//     });
// }

export function questionnarieStatusUpdateService(
  payload: IStatusAprrovalQuestionnarie
) {
  return apiFunctions
    .post(siteUrls.questionnarie.statusForApprovalRequest, payload)
    .then((questionnarie) => {
      return questionnarie;
    })
    .catch((err: AxiosError) => {
      errorHandler(err);
    });
}

export function questionnarieFavourite(payload: IFavourite) {
  return apiFunctions
    .post(siteUrls.questionnarie.favourite.updateStatus, payload)
    .then((favourite) => {
      return favourite;
    })
    .catch((err: AxiosError) => {
      errorHandler(err);
    });
}

export function questionnarieDelete(id: string) {
  return apiFunctions
    .delete(`${siteUrls.questionnarie.delete}/${id}`)
    .then((deleted) => {
      return deleted;
    })
    .catch((err: AxiosError) => {
      errorHandler(err);
    });
}

export function checkQuestionnarieDeleteStatus(id: string) {
  return apiFunctions
    .get(`${siteUrls.questionnarie.checkQuestionnarieDeleteStatus}/${id}`)
    .then((deleted) => {
      return deleted;
    })
    .catch((err: AxiosError) => {
      errorHandler(err);
    });
}

export function questionnarieFilter(params: any) {
  const queryString = objQueryConversion(params);
  return apiFunctions
    .get(`${siteUrls.questionnarie.filter}?${queryString}`)
    .then((favourite) => {
      return favourite;
    })
    .catch((err: AxiosError) => {
      errorHandler(err);
    });
}

export function _questionnarieShareGetPatients() {
  return shareUser;
}
export function questionnarieShareGetClients(payload: any) {
  const queryString = objQueryConversion(payload);
  return apiFunctions
    .get(`${siteUrls.user.getClientList}?${queryString}`)
    .then((users) => {
      return users;
    })
    .catch((err: AxiosError) => {
      errorHandler(err);
    });
}

// export function getScheduleShareQuestionnarie(payload: any) {
//   const queryString = objQueryConversion(payload);
//   return apiFunctions
//     .get(
//       `${siteUrls.questionnarie.getScheduleShareQuestionnaire}?${queryString}`
//     )
//     .then((users) => {
//       return users;
//     })
//     .catch((err: AxiosError) => {
//       errorHandler(err);
//     });
// }

export function deleteScheduleShareQuestionnarie(id: string) {
  return apiFunctions
    .delete(`${siteUrls.questionnarie.getScheduleShareQuestionnaire}/${id}`)
    .then((deleted) => {
      return deleted;
    })
    .catch((err: AxiosError) => {
      errorHandler(err);
    });
}

export function questionnarieCheckQuestionName(name: string) {
  return apiFunctions
    .post(siteUrls.questionnarie.checkQuestionName, { name })
    .then((users) => {
      return users;
    })
    .catch((err: AxiosError) => {
      errorHandler(err);
    });
}

// export function questionnarieCheckExistingQuestionName(name: string, id: any) {
//   return apiFunctions
//     .post(siteUrls.questionnarie.checkExistingQuestionName, { name, id })
//     .then((users) => {
//       return users;
//     })
//     .catch((err: AxiosError) => {
//       errorHandler(err);
//     });
// }

export function questionnarieShareWithPatients(payload: IShareQuestionnarie) {
  return apiFunctions
    .post(siteUrls.questionnarie.shareQuestionnarie, payload)
    .then((share) => {
      return share;
    })
    .catch((err: AxiosError) => {
      errorHandler(err);
    });
}

// export function shareCounsellingQuestionnarie() {
//   return apiFunctions
//     .post(siteUrls.questionnarie.shareCounsellingQuestionnarie, {})
//     .then((share) => {
//       return share;
//     })
//     .catch((err: AxiosError) => {
//       errorHandler(err);
//     });
// }

// export function createDuplicateQuestionnaireApi(
//   payload: saveDuplicateQuestionnaireInterface
// ) {
//   return apiFunctions
//     .post(siteUrls.questionnarie.createDuplicateQuestionnaire, payload)
//     .then((duplicate) => {
//       return duplicate;
//     })
//     .catch((err: AxiosError) => {
//       errorHandler(err);
//     });
// }

// export function getResponseQuestionnaireList(
//   payload: IQuestionnaireQueryForAPI
// ) {
//   const queryString = objQueryConversion(payload);
//   let url: string;
//   if (queryString) {
//     url = `${siteUrls.questionnarie.getResponse}?${queryString}`;
//   } else {
//     url = `${siteUrls.questionnarie.getResponse}`;
//   }

//   return apiFunctions
//     .get(url)
//     .then((responses) => {
//       return responses;
//     })
//     .catch((err: AxiosError) => {
//       errorHandler(err);
//     });
// }

// export function getResponseQuestionnaireInDetail(
//   payload: IQuestionnaireQueryForAPI
// ) {
//   const queryString = objQueryConversion(payload);
//   let url: string;
//   if (queryString) {
//     url = `${siteUrls.questionnarie.getResponseInDetail}?${queryString}`;
//   } else {
//     url = `${siteUrls.questionnarie.getResponseInDetail}`;
//   }
//   return apiFunctions
//     .get(url)
//     .then((getResponseQuestionnaireInDetail) => {
//       return getResponseQuestionnaireInDetail;
//     })
//     .catch((err: AxiosError) => {
//       errorHandler(err);
//     });
// }

export function enableDisablePublic(payload: IQuestionnaireQueryForAPI) {
  return apiFunctions
    .post(siteUrls.questionnarie.enableDisablePublic, payload)
    .then((enableDisablePublic) => {
      return enableDisablePublic;
    })
    .catch((err: AxiosError) => {
      errorHandler(err);
    });
}

// export function getNotification() {
//   return apiFunctions
//     .get(`${siteUrls.getNotification}`)
//     .then((notification) => {
//       return notification.result;
//     })
//     .catch((err: AxiosError) => {
//       errorHandler(err);
//     });
// }

// export function getResource(payload: any) {
//   return apiFunctions
//     .post(`${siteUrls.resource.getContentsResource}`, payload)
//     .then((resource) => {
//       return resource;
//     })
//     .catch((err: AxiosError) => {
//       errorHandler(err);
//     });
// }

export function updateRecommendResource(payload: any, id: string) {
  return apiFunctions
    .put(`${siteUrls.questionnarie.updateRecommendResource}/${id}`, payload)
    .then((circle) => {
      return circle;
    })
    .catch((err: AxiosError) => {
      errorHandler(err);
    });
}

// export function getFilterResource() {
//   return apiFunctions
//     .get(`${siteUrls.resource.getFilterResource}`)
//     .then((resource) => {
//       return resource;
//     })
//     .catch((err: AxiosError) => {
//       errorHandler(err);
//     });
// }

// export function closeNotification(payload: IQuestionnaireQueryForAPI) {
//   return apiFunctions
//     .put(`${siteUrls.getNotification}`, payload)
//     .then((notification) => {
//       return notification.result;
//     })
//     .catch((err: AxiosError) => {
//       errorHandler(err);
//     });
// }

export function getClientForms(payload: IPatientQueryForApi) {
  const queryString = objQueryConversion(payload);
  let url: string;
  if (queryString) {
    url = `${siteUrls.questionnarie.getClientForms}?${queryString}`;
  } else {
    url = `${siteUrls.questionnarie.getClientForms}`;
  }
  return apiFunctions
    .get(url)
    .then((getResponseQuestionnaireInDetail) => {
      return getResponseQuestionnaireInDetail;
    })
    .catch((err: AxiosError) => {
      errorHandler(err);
    });
}

export function getClientFormsResponses(payload: IPatientQueryForApi) {
  const queryString = objQueryConversion(payload);
  let url: string;
  if (queryString) {
    url = `${siteUrls.questionnarie.getClientFormsResponses}?${queryString}`;
  } else {
    url = `${siteUrls.questionnarie.getClientFormsResponses}`;
  }
  return apiFunctions
    .get(url)
    .then((getResponseQuestionnaireInDetail) => {
      return getResponseQuestionnaireInDetail;
    })
    .catch((err: AxiosError) => {
      errorHandler(err);
    });
}

export function sendInstantReminder(payload: IActionBtnInterface) {
  return apiFunctions
    .post(`${siteUrls.questionnarie.sendInstandReminder}`, payload)
    .then((notification) => {
      return notification.result;
    })
    .catch((err: AxiosError) => {
      errorHandler(err);
    });
}

// export function downloadResponseAsPdf(payload: IActionBtnInterface) {
//   const queryString = objQueryConversion(payload);
//   let url: string;
//   if (queryString) {
//     url = `${siteUrls.questionnarie.responsePdfDownlaod}?${queryString}`;
//   } else {
//     url = `${siteUrls.questionnarie.responsePdfDownlaod}`;
//   }
//   return apiFunctions
//     .get(url)
//     .then((pdfBase64) => {
//       return pdfBase64.result;
//     })
//     .catch((err: AxiosError) => {
//       errorHandler(err);
//     });
// }

export function createUser(payload: any) {
  return apiFunctions
    .post(siteUrls.user.createUser, payload)
    .then((users) => {
      return users;
    })
    .catch((err: AxiosError) => {
      errorHandler(err);
    });
}

export function questionnariePatientAnswer(payload: IQuestionAnswerAPI) {
  return apiFunctions
    .post(siteUrls.questionnarie.patientAnswer, payload)
    .then((answer) => {
      return answer;
    })
    .catch((err: AxiosError) => {
      errorHandler(err);
    });
}

export function questionnarieAddtionalInformation(payload: IQuestionAnswerAPI) {
  return apiFunctions
    .post(siteUrls.questionnarie.additionalInformation, payload)
    .then((answer) => {
      return answer;
    })
    .catch((err: AxiosError) => {
      errorHandler(err);
    });
}

export function questionnarieUpdatePatientAnswerByAdmin(
  payload: IQuestionAnswerAPI
) {
  return apiFunctions
    .post(siteUrls.questionnarie.adminUpdatePatientAnswer, payload)
    .then((answer) => {
      return answer;
    })
    .catch((err: AxiosError) => {
      errorHandler(err);
    });
}

export function questionnarieGetClientEditableAnswer() {
  return apiFunctions
    .get(siteUrls.questionnarie.clientEditableAnswer)
    .then((answer) => {
      return answer;
    })
    .catch((err: AxiosError) => {
      errorHandler(err);
    });
}
export function questionnarieGetPatientAnswer(payload: any) {
  const queryString = objQueryConversion(payload);
  let url: string;
  if (queryString) {
    url = `${siteUrls.questionnarie.patientAnswer}?${queryString}`;
  } else {
    url = `${siteUrls.questionnarie.patientAnswer}`;
  }
  return apiFunctions
    .get(url)
    .then((answer) => {
      return answer;
    })
    .catch((err: AxiosError) => {
      errorHandler(err);
    });
}

export function getAllCallList(filter?: any) {
  let url = `${siteUrls.questionnarie.dashboard.getAllCallList}`;
  if (filter) {
    const queryString = objQueryConversionWithSearch(filter);
    url = `${siteUrls.questionnarie.dashboard.getAllCallList}?${queryString}`;
  }
  return apiFunctions
    .get(url)
    .then((circle) => {
      return circle;
    })
    .catch((err: AxiosError) => {
      errorHandler(err);
    });
}
// Fetching triage work list data
export function getAllWorkList(
  filter?: {},
  payload?: {
    page: number;
    rowPerPage: number;
  }
) {
  let url = `${siteUrls.questionnarie.dashboard.getAllWorkList}`;
  if (filter) {
    const queryString = objQueryConversionWithSearch(
      (filter = {
        ...filter,
        pageNo: payload?.page,
        perPage: payload?.rowPerPage,
      })
    );
    url = `${siteUrls.questionnarie.dashboard.getAllWorkList}?${queryString}`;
  }
  return apiFunctions
    .get(url)
    .then((circle) => {
      return circle;
    })
    .catch((err: AxiosError) => {
      errorHandler(err);
    });
}
// When the user updates the trigger 'Action' column, it will be updated.
export function updateTriageAction(id: string, userId: string, payload: any) {
  const TRIAGE_ACTION_UPDATE_URL = `${siteUrls.questionnarie.dashboard.updateTriageAction}/${id}/${userId}`;
  return apiFunctions
    .put(TRIAGE_ACTION_UPDATE_URL, payload)
    .then((circle) => {
      return circle;
    })
    .catch((err: AxiosError) => {
      errorHandler(err);
    });
}
// retrieve the overall count of triggers
export function retrieveTriggerOverviewCount() {
  const TRIAGE_OVERALL_COUNT_URL = `${siteUrls.questionnarie.dashboard.triggerOverallCount}`;
  return apiFunctions
    .get(TRIAGE_OVERALL_COUNT_URL)
    .then((circle) => {
      return circle;
    })
    .catch((err: AxiosError) => {
      errorHandler(err);
    });
}
export function getCompletedWorkList(
  filter?: {},
  payload?: {
    pageNo: number;
    perPage: number;
  }
) {
  let url = `${siteUrls.questionnarie.dashboard.getCompletedWorkList}`;
  if (filter) {
    const queryString = objQueryConversionWithSearch(
      (filter = {
        ...filter,
        pageNo: payload?.pageNo,
        perPage: payload?.perPage,
      })
    );
    url = `${siteUrls.questionnarie.dashboard.getCompletedWorkList}?${queryString}`;
  }
  return apiFunctions
    .get(url)
    .then((circle) => {
      return circle;
    })
    .catch((err: AxiosError) => {
      errorHandler(err);
    });
}

export function getUnattendedCallback(
  filter?: {},
  payload?: {
    pageNo: number;
    perPage: number;
  }
) {
  let url = `${siteUrls.questionnarie.dashboard.getUnattendedCallback}`;
  if (filter) {
    const queryString = objQueryConversionWithSearch(
      (filter = {
        ...filter,
        pageNo: payload?.pageNo,
        perPage: payload?.perPage,
      })
    );
    url = `${siteUrls.questionnarie.dashboard.getUnattendedCallback}?${queryString}`;
  }
  return apiFunctions
    .get(url)
    .then((circle) => {
      return circle;
    })
    .catch((err: AxiosError) => {
      errorHandler(err);
    });
}

export function getCompletedCallback(
  filter?: {},
  payload?: {
    pageNo: number;
    perPage: number;
  }
) {
  let url = `${siteUrls.questionnarie.dashboard.getCompletedCallback}`;
  if (filter) {
    const queryString = objQueryConversionWithSearch(
      (filter = {
        ...filter,
        pageNo: payload?.pageNo,
        perPage: payload?.perPage,
      })
    );
    url = `${siteUrls.questionnarie.dashboard.getCompletedCallback}?${queryString}`;
  }
  return apiFunctions
    .get(url)
    .then((circle) => {
      return circle;
    })
    .catch((err: AxiosError) => {
      errorHandler(err);
    });
}

export function getAdminTodayCallback(
  filter?: {},
  payload?: {
    pageNo: number;
    perPage: number;
  }
) {
  let url = `${siteUrls.questionnarie.dashboard.getAdminTodayCallback}`;
  if (filter) {
    const queryString = objQueryConversionWithSearch(
      (filter = {
        ...filter,
        pageNo: payload?.pageNo,
        perPage: payload?.perPage,
      })
    );
    url = `${siteUrls.questionnarie.dashboard.getAdminTodayCallback}?${queryString}`;
  }
  return apiFunctions
    .get(url)
    .then((circle) => {
      return circle;
    })
    .catch((err: AxiosError) => {
      errorHandler(err);
    });
}

export function getAdminUnattendedCallback(
  filter?: {},
  payload?: {
    pageNo: number;
    perPage: number;
  }
) {
  let url = `${siteUrls.questionnarie.dashboard.getAdminUnattendedCallback}`;
  if (filter) {
    const queryString = objQueryConversionWithSearch(
      (filter = {
        ...filter,
        pageNo: payload?.pageNo,
        perPage: payload?.perPage,
      })
    );
    url = `${siteUrls.questionnarie.dashboard.getAdminUnattendedCallback}?${queryString}`;
  }
  return apiFunctions
    .get(url)
    .then((circle) => {
      return circle;
    })
    .catch((err: AxiosError) => {
      errorHandler(err);
    });
}

export function getAdminCompletedCallback(
  filter?: {},
  payload?: {
    pageNo: number;
    perPage: number;
  }
) {
  let url = `${siteUrls.questionnarie.dashboard.getAdminCompletedCallback}`;
  if (filter) {
    const queryString = objQueryConversionWithSearch(
      (filter = {
        ...filter,
        pageNo: payload?.pageNo,
        perPage: payload?.perPage,
      })
    );
    url = `${siteUrls.questionnarie.dashboard.getAdminCompletedCallback}?${queryString}`;
  }
  return apiFunctions
    .get(url)
    .then((circle) => {
      return circle;
    })
    .catch((err: AxiosError) => {
      errorHandler(err);
    });
}

export function createAuditTrail(payload: any) {
  return apiFunctions
    .post(siteUrls.auditTrail.create, payload)
    .then((circle) => {
      return circle;
    })
    .catch((err: AxiosError) => {
      errorHandler(err);
    });
}

export function deleteTriageDeleteRequestedUserByUserId(userId: string) {
  return apiFunctions
    .delete(`${siteUrls.adminFunctions.deleteRequestedUserDeletion}/${userId}`)
    .then((circle) => {
      return circle;
    })
    .catch((err: AxiosError) => {
      errorHandler(err);
    });
}

// export function deleteAuditTrailByUserId(userId: string) {
//   return apiFunctions
//     .delete(`${siteUrls.auditTrail.create}/${userId}`)
//     .then((circle) => {
//       return circle;
//     })
//     .catch((err: AxiosError) => {
//       errorHandler(err);
//     });
// }

export function getAuditTrail(id: string) {
  return apiFunctions
    .get(`${siteUrls.auditTrail.get}/${id}`)
    .then((circle) => {
      return circle;
    })
    .catch((err: AxiosError) => {
      errorHandler(err);
    });
}

export function getAuditTrailAccountHistory() {
  return apiFunctions
    .get(`${siteUrls.auditTrail.getAccountHistory}`)
    .then((circle) => {
      return circle;
    })
    .catch((err: AxiosError) => {
      errorHandler(err);
    });
}

export function getClientOverviewDetailsByID(clientID: string) {
  return apiFunctions
    .get(
      `${siteUrls.questionnarie.dashboard.clientOverviewDetailsByIDAndService}/${clientID}`
    )
    .then((circle) => {
      return circle;
    })
    .catch((err: AxiosError) => {
      errorHandler(err);
    });
}

export function getClientRecords(payload?: {
  pageNo: number;
  perPage: number;
  searchText?: string;
}) {
  const queryString = objQueryConversionWithSearch(payload);
  let url: string;
  if (queryString) {
    url = `${siteUrls.questionnarie.dashboard.clientRecord}?${queryString}`;
  } else {
    url = `${siteUrls.questionnarie.dashboard.clientRecord}`;
  }
  return apiFunctions
    .get(url)
    .then((circle) => {
      return circle;
    })
    .catch((err: AxiosError) => {
      errorHandler(err);
    });
}

export function updateClientDashboard(payload: any, id: string) {
  return apiFunctions
    .put(
      `${siteUrls.questionnarie.dashboard.updateClientDashboard}/${id}`,
      payload
    )
    .then((circle) => {
      return circle;
    })
    .catch((err: AxiosError) => {
      errorHandler(err);
    });
}

export function updateClientDashboardSet(payload: any, id: string) {
  return apiFunctions
    .put(
      `${siteUrls.questionnarie.dashboard.updateClientDashboardSet}/${id}`,
      payload
    )
    .then((circle) => {
      return circle;
    })
    .catch((err: AxiosError) => {
      errorHandler(err);
    });
}

export function updateQuestionnaireShareByUserId(userid: string, payload: any) {
  return apiFunctions
    .put(
      `${siteUrls.questionnarie.updateQuestionnaireShareByUserId}/${userid}`,
      payload
    )
    .then((user) => {
      return user;
    })
    .catch((err: AxiosError) => {
      errorHandler(err);
    });
}

export function getQuestionnaireShareByUserIdBoolean(userid: string) {
  return apiFunctions
    .get(
      `${siteUrls.questionnarie.getQuestionnaireShareByUserIdBoolean}/${userid}`
    )
    .then((user) => {
      return user;
    })
    .catch((err: AxiosError) => {
      errorHandler(err);
    });
}

// Get appointment for LARC service
export function getAppointment() {
  return apiFunctions
    .get("/maxims/appointment")
    .then((response) => {
      return response;
    })
    .catch((err: AxiosError) => {
      errorHandler(err);
    });
}

// Get multiple appointment for all service
export function getMultipleAppointment(payload?: {
  startDate?: Date | null;
  endDate?: Date | null;
}) {
  const queryString = objQueryConversionWithSearch(payload);
  let url: string;
  if (queryString) {
    url = `${siteUrls.questionnarie.dashboard.multipleAppointment}?${queryString}`;
  } else {
    url = `${siteUrls.questionnarie.dashboard.multipleAppointment}`;
  }
  return apiFunctions
    .get(url)
    .then((response) => {
      return response;
    })
    .catch((err: AxiosError) => {
      errorHandler(err);
    });
}
