import { lazy } from "react";

export default [
  {
    title: "Update Questionnaires",
    component: lazy(() =>
      import("./QuestionnaireBuilder/QuestionnaireBuilder").then(
        ({ QuestionnaireBuilder }) => ({
          default: QuestionnaireBuilder,
        })
      )
    ),
    url: "/questionnaires/builder/:id",
    heading: "UpdateQuestionnaires",
    requirePermission: true,
    roles: ["Super_Admin", "Admin"],
  },
  {
    title: "Questionnaires Forms",
    component: lazy(() =>
      import("./FormQuestionnaire").then(({ FormQuestionnaire }) => ({
        default: FormQuestionnaire,
      }))
    ),
    url: "/questionnaires/form",
    heading: "QuestionnairesForms",
    requirePermission: true,
    roles: ["Super_Admin", "Admin"],
  },
  {
    title: "Questionnaires Forms",
    component: lazy(() =>
      import("./FormQuestionnaire").then(({ FormQuestionnaire }) => ({
        default: FormQuestionnaire,
      }))
    ),
    url: "/questionnaires/form/all",
    heading: "QuestionnairesForms",
    requirePermission: true,
    roles: ["Super_Admin", "Admin"],
  },
  {
    title: "Create Questionnaires",
    component: lazy(() =>
      import("./QuestionnaireBuilder/QuestionnaireBuilder").then(
        ({ QuestionnaireBuilder }) => ({
          default: QuestionnaireBuilder,
        })
      )
    ),
    url: "/questionnaires/builder/",
    heading: "CreateQuestionnaires",
    requirePermission: true,
    roles: ["Super_Admin", "Admin"],
  },
  {
    title: "Create Questionnaires",
    component: lazy(() =>
      import("./CreateQuestionnaire").then(({ CreateQuestionnaire }) => ({
        default: CreateQuestionnaire,
      }))
    ),
    url: "/questionnaires/form/create",
    heading: "CreateQuestionnaires",
    requirePermission: true,
    roles: ["Super_Admin", "Admin"],
  },
  {
    title: "Client Eform Dashboard",
    component: lazy(() => import("./ClientEform-Dashboard")),
    url: "/client/dashboard",
    heading: "CreateQuestionnaires",
    requirePermission: true,
    roles: ["Client"],
  },
  {
    title: "Client Eform Account",
    component: lazy(() => import("./ClientEform-Account")),
    url: "/client/account",
    heading: "CreateQuestionnaires",
    requirePermission: true,
    roles: ["Client"],
  },
  {
    title: "Questionnarie",
    component: lazy(() => import("./ViewQuestionForms")),
    url: "/questionnaires/viewform/:id/:shareId",
    heading: "Questionnarie",
    requirePermission: true,
    roles: ["Client", "Super_Admin", "Admin"],
  },
  {
    title: "Questionnarie",
    component: lazy(() => import("./ViewQuestionForms")),
    url: "/questionnaires/editform/:id/:shareId",
    heading: "Questionnarie",
    requirePermission: true,
    roles: ["Client", "Admin", "Super_Admin"],
  },
  {
    title: "FormApproval",
    component: lazy(() => import("./FormApproval")),
    url: "/questionnaires/form/approvals",
    heading: "FormApproval",
    requirePermission: true,
    roles: ["Super_Admin", "Admin"],
  },
  {
    title: "Dashboard",
    component: lazy(() => import("./Dashboard")),
    url: "/admin/dashboard",
    heading: "DashboardQuestionnaires",
    requirePermission: true,
    roles: ["Super_Admin", "Admin"],
  },
  {
    title: "Worklist & Triage",
    // component: lazy(() => import("./TriageWorklist")),
    component: lazy(() => import("./triage/TriageWorkListNew")),
    url: "/admin/dashboard/triage/:tabName?",
    heading: "TriageWorklist",
    requirePermission: true,
    roles: ["Super_Admin", "Admin"],
  },
  {
    title: "Responses",
    component: lazy(() => import("./Responses")),
    url: "/questionnaires/responses/:id/:menuName?/:tabName?",
    heading: "QuestionnairesResponses",
    requirePermission: true,
    roles: ["Super_Admin", "Admin"],
  },
];
