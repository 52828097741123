import React from "react";
import "../../style/components/Radio.css";

interface RadioProps {
  checked?: boolean;
  label?: string | any;
  name?: string;
  type?: string;
  value?: string;
  handleChange?: any;
  disabled?: boolean;
}

export const Radio: React.FC<RadioProps> = ({
  checked,
  label,
  name,
  type,
  value,
  handleChange,
  disabled,
}) => {
  return (
    <div className="radio-group">
      <label>
        {label}
        <input
          type={type}
          checked={checked || false}
          name={name}
          value={value}
          onChange={handleChange}
          disabled={disabled}
        />
        <span className="radio" />
      </label>
    </div>
  );
};
