import React, { createContext, useContext, useEffect, useReducer } from "react";

// import { getUserInfo } from "../../services/api/AuthApi";
// import { appUrls } from "../../services/AppURLs";

import { isTokenValid } from "../../services/ApiService";
import {
  AuthAction,
  AuthState,
  authReducer,
  initialState,
} from "../reducers/AuthReducer";
import { TOKEN_KEY, decodeUserInfo, getCookie } from "../../utils/useCookie";

// a authDispatch can only do action types set in reducer
type AuthContextProps = {
  state: AuthState;
  authDispatch: React.Dispatch<AuthAction>;
  checkAuth?: any;
};

const AuthContext = createContext<AuthContextProps>({
  state: initialState,
  authDispatch: () => initialState,
});

export function AuthContextProvider(props: React.PropsWithChildren<{}>) {
  const [state, authDispatch] = useReducer(authReducer, initialState);

  async function checkAuth() {
    // const userDetails: any = await localStorage.getItem("userDetails");
    const userAccessToken: any = getCookie(TOKEN_KEY);    

    if (!userAccessToken) return;
    if (userAccessToken && isTokenValid(userAccessToken)) {
      const userInfo = decodeUserInfo(userAccessToken);
      try {
        // const userInfo = await getUserInfo();
        // if (userInfo) {
        //   console.log(userInfo, 'info');
        //   authDispatch({ type: 'LOGIN' });
        //   const responseModified = { ...userInfo.result };
        //   console.log('responseModified', responseModified);
        //   responseModified.role = responseModified.internalRole;
        //   if (
        //     responseModified.internalRole === 'clinician' ||
        //     responseModified.internalRole === 'patient'
        //   ){
        authDispatch({ type: "LOAD_USER", user: userInfo });
        // authDispatch({ type: 'SET_GLOBAL_SETTINGS', globalSettings: {
        //   modules: convertModuleSettings({modules: responseModified.tpInfo[0].questionnaires.data}),
        //   theme: [],
        // }});

        //  } else {
        //   // authDispatch({ type: 'LOGOUT' });
        //  }
      } catch (error) {
        console.log("error", error);
      }
    } else {
      authDispatch({ type: "LOGOUT" });
    }
  }

  // Module conversion to object from array
  // function convertModuleSettings(moduleVal: any) {
  //   const modules: any = {};
  //   modules.card_question = moduleVal.modules.card_question;
  //   modules.questionnaires = {};
  //   moduleVal.modules.questionnaires.forEach((key: any) => {
  //     modules.questionnaires[key.name] = key.active;
  //   });
  //   return modules;
  // }

  useEffect(() => {
    // if (document.referrer !== appUrls.outerDomainUrls.amityparent.home) {
    checkAuth();
    //}
  }, []);

  return (
    <AuthContext.Provider
      value={{ state, authDispatch, checkAuth }}
      {...props}
    />
  );
}

export default function useAuthContext() {
  return useContext(AuthContext);
}
