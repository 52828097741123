import { lazy } from 'react';

export default [
  {
    title: 'counselling appointment',
    component: lazy(() => import('./book_counselling_appointment')),
    url: '/vas/counselling',
    requirePermission: true,
    roles: ["Super_Admin", "Admin", "Client"],
  },
  {
    title: 'counselling appointment',
    component: lazy(() => import('./book_counselling_appointment')),
    url: '/vas/counselling/:id/:shareId',
    requirePermission: true,
    roles: ["Super_Admin", "Admin", "Client"],
  },
]