import Cookies from "universal-cookie";
import jwtDecode from "jwt-decode";

const cookies = new Cookies();

export const TOKEN_KEY = "token";

export const setCookie = (key: string, value: any, options?: any) => {
  cookies.set(key, value, options);
};

export const getCookie = (key: string) => {
  return cookies.get(key);
};

export const removeCookie = (key: string, options?: any) => {
  cookies.remove(key, options);
};

// Retrieve user information by decoding the JWT token.
export const decodeUserInfo = (token: string) => {
  if (token) {
    const decoded: any = jwtDecode(token);
    const decodeUserObj = {
      id: decoded?.id || "",
      email: decoded?.email || "",
      usertype: decoded?.usertype || "",
      userName: decoded?.firstname || "",
    };
    return decodeUserObj;
  } else {
    return {
      id: "",
      email: "",
      usertype: "",
      userName: "",
    };
  }
};
