import React, { lazy, useState, useEffect } from "react";
import {
  Route,
  Routes,
  useLocation,
  Navigate,
  useNavigate,
  useNavigationType,
} from "react-router-dom";
import "./Style.css";

import "./style/questionnaire/ChooseTags.css";
import "./style/questionnaire/styles.css";
import routes from "./routes";
const PageNotFound = lazy(() => import("./pages/auth/PageNotFound"));
const External_Login = lazy(() => import("./pages/home/external-azure-login"));
import { MainLayout } from "./Layout/MainLayout";
import { StaffMainLayout } from "./Layout/StaffMainLayout";
import { QuestionLayout } from "./Layout/QuestionLayout";

import { Header } from "./components/Header";
import { Azure_redirect } from "./pages/home/azure_redirect";
import StaffAdminPage from "./pages/userlisting/Home";
import InactiveAlert from "./components/InactiveAlert";
import { UserTypes } from "./models/UserTypes";
import { PreviewContext } from "./context/provider/PreviewContext";
import SmoothScroll from "./components/questionnaires/SmoothScroll/SmoothScroll";
import { useDispatch, useSelector } from "react-redux";
import { IFilter } from "./pages/questionnaires/triage/TriageType";
import { setFilterOptions } from "./redux/triageWorkListSlice";
// import { TOKEN_KEY, getCookie } from "./utils/useCookie";
// import { setCookieUserToken } from "./redux/authSlice";

function SessionExpired() {
  return <p>Session Expired</p>;
}
function InitialPage() {
  return <Navigate to='/auth/login' />;
}

const initialFilterState: IFilter = {
  searchText: "",
  services: [],
  categories: [],
  rightcareTriggers: [],
  safety: [],
  status: [],
  others: [],
  ageFrom: "",
  ageTo: "",
  dateFrom: "",
  dateTo: "",
};

function App() {
  const [preview, setPreview] = useState({ disabled: false });

  const navigate = useNavigate();
  const location = useLocation();
  const nvtype = useNavigationType();
  const dispatch: any = useDispatch();
  const authSelector = useSelector((state: any) => state.userAuthReducer);
  const {
    userInfo: { usertype },
  } = authSelector;

  useEffect(() => {
    console.log("authSelector 72 :>>", authSelector);
  }, [authSelector]);

  useEffect(() => {
    if (nvtype === "POP") {
      if (usertype == "Client") {
        return navigate("/client/dashboard");
      }
    }

    /* Reset the global triage work list filter state value when the route changes */
    if (
      !(location?.pathname.indexOf("/admin/dashboard/triage") !== -1) &&
      !(location?.pathname.indexOf("/questionnaires/responses/") !== -1)
    ) {
      const filterParam: any = { filterParam: initialFilterState };
      dispatch(setFilterOptions(filterParam));
    }
  }, [location]);

  useEffect(() => {
    const bodyElement = document.querySelector("body");
    const userRoleList = ["Admin", "Super_Admin"];
    if (usertype && !userRoleList.includes(usertype)) {
      bodyElement?.classList.add("client-block");
    } else {
      bodyElement?.classList.remove("client-block");
    }
    return () => {
      bodyElement?.classList.remove("client-block");
    };
  }, [usertype]);

  function RequireAuth({ children }: { children: JSX.Element }) {
    const location = useLocation();
    try {
      const userDetails: any = authSelector;
      if (userDetails) {
        return children;
      }
      return <Navigate to='/auth/login' state={{ from: location }} replace />;
    } catch (error) {
      console.log(error);
      return <Navigate to='/auth/login' state={{ from: location }} replace />;
    }
  }

  function RedirectToDashboard({ children }: { children: JSX.Element }) {
    try {
      if (usertype) {
        if (usertype == "Client") {
          console.log("log 125...");
          return <Navigate to='/client/dashboard' />;
        } else if (usertype == "Admin" || usertype == "Super_Admin") {
          return <Navigate to='/admin/dashboard' />;
        }
      }
      return children;
    } catch (error) {
      console.log(error);
      return children;
    }
  }

  // const userDetails: any = authSelector;
  // const Role = userDetails?.usertype;

  return (
    <PreviewContext.Provider value={{ preview, setPreview }}>
      <SmoothScroll>
        <Routes>
          <Route
            path='/'
            element={
              <RedirectToDashboard>
                <InitialPage />
              </RedirectToDashboard>
            }
          />
          <Route path='/azure_redirect' element={<Azure_redirect />} />
          <Route
            path='/Staff/AdminDashboard'
            element={
              <RequireAuth>
                <StaffMainLayout>
                  <StaffAdminPage></StaffAdminPage>
                </StaffMainLayout>
              </RequireAuth>
            }
          />

          {routes.map(
            ({
              url,
              requirePermission,
              roles,
              component: Component,
              title,
              heading,
            }: any) => {
              if (
                usertype &&
                requirePermission &&
                roles?.indexOf(usertype) > -1
              ) {
                if (
                  usertype == UserTypes.admin ||
                  usertype == UserTypes.superAdmin
                ) {
                  return (
                    <Route
                      key={url}
                      path={url}
                      element={
                        <RequireAuth>
                          <>
                            <QuestionLayout>
                              <Component title={title} />
                            </QuestionLayout>
                          </>
                        </RequireAuth>
                      }
                    />
                  );
                } else {
                  return (
                    <Route
                      key={url}
                      path={url}
                      element={
                        <RequireAuth>
                          <MainLayout headertext={heading}>
                            <InactiveAlert />

                            <Component title={title} />
                          </MainLayout>
                        </RequireAuth>
                      }
                    />
                  );
                }
              }
              //If the Requested URL needs permission and the User Role is Undefined or null
              else if (requirePermission && !usertype) {
                return (
                  <Route
                    key={url}
                    path={url}
                    element={
                      <RequireAuth>
                        <MainLayout headertext={heading}>
                          <InactiveAlert />

                          <Component title={title} />
                        </MainLayout>
                      </RequireAuth>
                    }
                  />
                );
              } else {
                return (
                  <Route
                    key={url}
                    path={url}
                    element={
                      <>
                        <Header probileshow />
                        <InactiveAlert />
                        <Component title={title} />
                      </>
                    }
                  />
                );
              }
            }
          )}
          <Route path='/session-expired' element={<SessionExpired />} />
          <Route path='/external-azure-login' element={<External_Login />} />
          <Route path='/*' element={<PageNotFound />} />
        </Routes>
      </SmoothScroll>
    </PreviewContext.Provider>
  );
}

export default App;
