import axiosAPI from '../middlewares/axios-interceptor';

const createSession = (data: any) => axiosAPI.post('/sessions', data);

const deleteSession = (data: any) => axiosAPI.delete('/sessions', data);

const signIn = (data: any) => axiosAPI.post('/signin', data);

const staffLogin = (data:any)=>axiosAPI.post('/staff-login',data);

const resetPassword = (data: any) => axiosAPI.post('/reset-password/request', data);

const resendOtpForPasswordReset = (data: any) => axiosAPI.post('/reset-password/resend-otp', data);

const verifyOtpForPasswordReset = (data: any) => axiosAPI.post('/reset-password/verify-otp', data);

const verifyTokenAndResetPassword = (data: any) => axiosAPI.post('/reset-password/verify-token', data);

const verifyTokenAndResetPin = (data: any) => axiosAPI.post('/reset-pin/verify-token', data);

const forgotPassword = (data:any) => axiosAPI.post('/forgotpassword', data);

const getotp = (data:any) => axiosAPI.post('/two-step-authentication',data);

const getEditOtpVerification = (data:any) => axiosAPI.post('/otp-verification-secondary',data);

const getEditOtp = (data:any) => axiosAPI.post('/secondaryMailOrPhone',data);

const otpVerify = (data:any) => axiosAPI.post('/otp-verification',data);

const logoutuser = ()=>axiosAPI.post('/logout',{});

export {
    createSession,
    deleteSession,
    signIn,
    staffLogin,
    resetPassword,
    getotp,
    getEditOtp,
    getEditOtpVerification,
    forgotPassword,
    logoutuser,
    otpVerify,
    resendOtpForPasswordReset,
    verifyOtpForPasswordReset,
    verifyTokenAndResetPassword,
    verifyTokenAndResetPin
};
